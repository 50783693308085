// General
export interface StringDictionary {
    [key: string]: string;
}

export const MAX_DATATABLE_REGS_BY_REQ = 20; // SINCRONIZAR CON BACK!!!

// AUTH
export const USER_ROLE = {
    S: 'Supervisor',
    M: 'Medical Staff',
    A: 'Administrative',
};

// Person
export const PERSON_TYPE_USER = 'U';
export const PERSON_TYPE_CLIENT = 'C';
export const PERSON_TYPE_TUTOR = 'T';

export const PERSON_TYPE = {
    U: 'User',
    C: 'Client',
    T: 'Tutor',
};

export const ORGANISATION_TYPE = {
    C: 'Company',
    I: 'Institution',
    F: 'Fundation',
    O: 'ONG',
    X: 'Organizar esto!',
};

export const CLIENT_TYPE_PERSON = 'P'; // Persona física
export const CLIENT_TYPE_ORGANISATION = 'O'; // Persona jurídica
export const CLIENT_TYPE_ICON = {
    CLIENT_TYPE_PERSON: 'face',
    CLIENT_TYPE_ORGANISATION_ICON: 'business',
};

export const PERSON_IDENTIFICACION_TYPES = {
    D: 'DNI',
    E: 'NIE',
    F: 'NIF',
    P: 'Passport'


    //R: 'Residence Card',
    //V: 'Driving License',
};

export const PERSON_TITLES: StringDictionary = {
    MR: 'Mr.',
    MS: 'Mrs.',
    /*   CT: "Count",
    MJ: "Your Majesty",
    YH: "Your highness",
   */
};

export const PERSON_STATUS_ACTIVE = 'A';

export const PERSON_STATUS = {
    A: 'Active',
    P: 'Pending',
    C: 'Cancelled',
};

export const TUTOR_PERSON_RELATIONS = {
    M: 'Mother',
    F: 'Father',
    T: 'Legal tutor',
};

// Agenda

// PENDIENTE: Estos temitas deberáin ir en el modulo de Agenda!
export const DAYS_OF_THE_WEEK = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
];

export const EVENT_RECURRING_PERIODS = {
    DW: 'Daily (monday to friday)',
    D: 'Daily',
    W: 'Weekly',
    M: 'Monthly',
    Y: 'Yearly',
};

export const EVENT_REMINDER_TYPES = {
    S: 'Screen notification',
    M: 'Email',
    W: 'WhatsApp',
};

export const EVENT_DURATION_TYPE_OPTIONS = {
    H: 'Hours',
    M: 'Minutes',
}

// En minutos por convenience, pero en principio no se utiliza como minutos, sólo es una codificación
export const EVENT_DURATION_OPTIONS = {
    5: '5 minutes',
    10: '10 minutes',
    20: '20 minutes',
    30: '30 minutes',
    45: '45 minutes',
    50: '50 minutes',
    60: '1 hour',
    120: '2 hours',
    180: '3 hours',
    240: '4 hours',
    300: '5 hours',
    360: '6 hours',
    420: '7 hours',
    480: '8 hours',
    540: '9 hour',
    600: '10 hours',
    1440: '1 day', // 60*24
    2880: '2 days',
    4320: '3 days',
    5760: '4 days',
    7200: '5 days',
    8640: '6 days',
    10080: '1 week', // 60*24*7
    20160: '2 weeks', // 60×24×7×2
    30240: '3 weeks', // 60×24×7×3
    43200: '1 month', // 60*24*30
    86400: '2 month', // 60*24*30*2
    129600: '3 month', // 60*24*30*3
    172800: '4 month', // 60*24*30*4
    216000: '5 month', // 60*24*30*5
    259200: '6 month', // 60*24*30*6
    525600: '1 year', // 60*24*365*1
    1051200: '2 years', // 60*24*365*2
};

// Invoiving

export const BUDGET_STATUS = {
    A: 'Active',
    C: 'Cancelled',
};

export const INVOICE_STATUS = {
    A: 'Active',
    P: 'Proforma',
    O: 'Paid',
    C: 'Cancelled',
};

// Product

export const PRODUCT_STATUS = {
    A: 'Active',
    C: 'Cancelled',
};

// Service

export const SERVICE_STATUS = {
    A: 'Active',
    C: 'Cancelled',
};

export const SERVICE_INVOICING_MODE = {
    F: 'Fixed price',
    H: 'Price per hour',
};

export const CLIENT_TYPE_OPTIONS = {
    P: 'Person',
    C: 'Company',
};

export const CLIENT_STATUS_OPTIONS = {
    A: 'Active',
    P: 'Pending',
    C: 'Cancelled',
};
