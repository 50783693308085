import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from 'app/core/auth/auth.service';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { Observable, catchError, switchMap, throwError } from 'rxjs';

/**
 * Apollo Interceptor
 *
 * @param req
 * @param next
 */
export const apolloAuthInterceptor = (
    req: HttpRequest<unknown>,
    next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
    // Inyecta el AuthService si lo necesitas para gestionar el cierre de sesión o acciones relacionadas
    const authService = inject(AuthService);

    // Clona la solicitud
    let newReq = req.clone();

    // Solo modifica las solicitudes relacionadas con GraphQL
    if (req.url.includes('/graphql')) {
        // Obtiene el token desde localStorage
        const token = authService.accessToken;

        // Si el token existe y no ha expirado, agrega el encabezado de autorización
        if (token && !AuthUtils.isTokenExpired(token)) {
            newReq = req.clone({
                headers: req.headers.set('Authorization', `Bearer ${token}`),
            });
        }
    }

    // Manejo de errores y respuesta
    return next(newReq).pipe(
        catchError((error) => {
            // Si ocurre un error 401, cierra sesión y recarga la aplicación
            if (error instanceof HttpErrorResponse && error.status === 401) {
                return authService.fetchNewTokenFromServer().pipe(
                    switchMap((newToken) => {
                        // Clona la solicitud original con el nuevo token
                        const clonedRequest = req.clone({
                            headers: req.headers.set('Authorization', `Bearer ${newToken}`),
                        });

                        // Reintenta la solicitud con el nuevo token
                        return next(clonedRequest);
                    }),
                    catchError((refreshError) => {
                        console.error(refreshError);
                        // Si no se puede refrescar el token, cierra la sesión
                        // authService.signOut();
                        // location.reload();
                        return throwError(() => new Error('Unable to refresh token'));
                    })
                );
            }

            // Propaga el error para el manejo adecuado en el flujo de la aplicación
            return throwError(error);
        })
    );
};
