import { BooleanInput } from '@angular/cdk/coercion';
import { CommonModule, NgClass } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnDestroy,
    OnInit,
    Signal,
    ViewEncapsulation,
    computed,
    inject,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { Router } from '@angular/router';
import { TranslocoDirective, provideTranslocoScope } from '@jsverse/transloco';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/infrastructure/graphql.generated';
import { SchemeService } from 'app/shared/services/scheme.service';
import { environment } from 'environments/environment';
import { Observable, Subject, takeUntil } from 'rxjs';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'user',
    templateUrl: './user.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'user',
    standalone: true,
    imports: [
        MatButtonModule,
        MatMenuModule,
        MatIconModule,
        NgClass,
        MatDividerModule,
        CommonModule,
        TranslocoDirective,
    ],
    providers: [provideTranslocoScope('auth')],
})
export class UserComponent implements OnInit, OnDestroy {
    static ngAcceptInputType_showAvatar: BooleanInput;

    @Input() showAvatar = true;
    // user: User;
    user$: Observable<User>;

    private _unsubscribeAll: Subject<void> = new Subject<void>();

    showAdditionalFeatures = !environment.production;

    private schemeService = inject(SchemeService);

    scheme: Signal<string> = computed(() => {
        return this.schemeService.scheme(); // Retorna el esquema actual
    });

    /**
     * Constructor
     */
    constructor(
        private _router: Router,
        private userService: UserService
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to user changes
        this.user$ = this.userService.get().pipe(takeUntil(this._unsubscribeAll));
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Sign out
     */
    signOut(): void {
        this._router.navigate(['/sign-out']);
    }

    setScheme(): void {
        this.schemeService.setScheme(this.scheme() === 'light' ? 'dark' : 'light');
    }
}
