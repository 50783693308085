import { Component, OnInit, computed, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { SignalsCurrentPageSettingsButtonService } from './signals-current-page-settings-button.service';

@Component({
    selector: 'app-current-page-settings-button',
    standalone: true,
    imports: [MatButtonModule, MatTooltipModule, MatIconModule],
    templateUrl: './current-page-settings-button.component.html',
})
export class CurrentPageSettingsButtonComponent implements OnInit {
    private signalsService = inject(SignalsCurrentPageSettingsButtonService);
    private router = inject(Router);

    currentRoute = this.signalsService.currentRoute;

    patterns = [
        '/operational/calendar',
        '/operational/calendar/*',

        // Futuras rutas

        // '/operational/clients',
        // '/operational/clients/*',
        // '/operational/business-services',
        // '/operational/business-services/*',
        // '/operational/billing',
        // '/operational/billing/*',
    ];

    isButtonDisabled = computed(() => {
        const route = this.currentRoute();
        return !this.signalsService.isRouteInNavigation(route, this.patterns);
    });

    constructor() {
        const initialRoute = this.router.url;
        this.signalsService.setCurrentRoute(initialRoute);
    }

    ngOnInit(): void {
        this.signalsService.setCurrentRoute(this.router.url);

        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                this.signalsService.setCurrentRoute(event.urlAfterRedirects);
                this.signalsService.setShouldOpenDrawer(false);
            });
    }

    // When clicked, set the signal for opening the drawer
    openDrawer(): void {
        if (!this.isButtonDisabled()) {
            this.signalsService.setShouldOpenDrawer(true);
        }
    }
}
