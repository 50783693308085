import { Injectable, effect, signal } from '@angular/core';

export type SchemeType = 'dense' | 'default';

@Injectable({
    providedIn: 'root',
})
export class DenseService {
    private readonly DENSE_KEY = 'denseMode';

    denseMode = signal<SchemeType>(this.getDenseMode());

    constructor() {
        effect(() => {
            localStorage.setItem(this.DENSE_KEY, this.denseMode());
        });
    }

    private getDenseMode(): SchemeType {
        const storedValue = localStorage.getItem(this.DENSE_KEY);
        return storedValue === 'dense' || storedValue === 'default' ? storedValue : 'dense';
    }

    setDenseMode(mode: SchemeType) {
        this.denseMode.set(mode);
    }
}
