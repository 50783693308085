import {gql} from 'apollo-angular';

import {PersonCompanyRelationTypeCreateInput} from "../../../../@interfaces/person-company-relation-type.interface";

export const CREATE_PERSON_COMPANY_ADDITIONAL_INFORMATION = gql`
    mutation createPersonCompanyAdditionalInformation($input: PersonCompanyAdditionalInformationCreateInput!) {
        createPersonCompanyAdditionalInformation(input: $input) {
            status
            message
            timeStamp
            data {
                id
                relationType
                personCompanyType
                status
                personCompanyId
            }
            errors {
                field
                type
            }
        }
    }
`;
