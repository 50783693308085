import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewEncapsulation, inject } from '@angular/core';
import { TranslocoDirective, provideTranslocoScope } from '@jsverse/transloco';

@Component({
    selector: 'app-error-404',
    templateUrl: './error-404.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [TranslocoDirective],
    providers: [provideTranslocoScope({ scope: 'error-pages', alias: 'errorPages' })],
})
export class Error404Component {
    private location = inject(Location);

    goBack() {
        this.location.back();
    }
}
