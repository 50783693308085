import { TypePolicies } from '@apollo/client/core';

export const cacheTypePolicies: TypePolicies = {
    Event: {
        fields: {
            participants: {
                keyArgs: false, // Ignore arguments when caching
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                merge(existing = [], incoming: any[] | null | undefined, { readField }) {
                    if (!incoming) {
                        return existing; // If incoming is null/undefined, return the existing data
                    }

                    const existingIds = new Set(existing.map((item) => readField('id', item)));
                    const merged = [...existing];

                    incoming.forEach((participant) => {
                        const id = readField('id', participant);
                        if (!existingIds.has(id)) {
                            merged.push(participant);
                            existingIds.add(id);
                        }
                    });

                    return merged;
                },
            },
        },
    },
};
