import {gql} from 'apollo-angular';

export const PERSON_COMPANY_TYPEAHEAD_QUERY = gql`
    query findAllPersonCompanyAdditionalInformation(
        $searchParams: PersonCompanyAdditionalInformationSearchInput,
        $limit: Int,
        $offset: Int,
        $orderBy: [OrderByInput]
    ) {
        findAllPersonCompanyAdditionalInformation(
            searchParams: $searchParams,
            limit: $limit,
            offset: $offset,
            orderBy: $orderBy
        ) {

            status
            message
            totalCount
            list {
                id
                personCompanyType
                personCompanyId
                email,
                phoneNumber,
                comments,
                relationType,
                person {
                    firstName,
                    lastName,
                    name, 
                    identification,
                    identificationType,
                }
                company {
                    tradename,
                    legalName,
                    legalIdentification,
                }
            }
        }
    }
`;
