<div class="main-menu">

	<div class="bloque-level1">

		<div *ngFor="let myGroup of menuItems">

			<section *ngIf="menuIsExpanded" class="h-2.5 main-menu-zoom-{{menuFontSize}}">{{
					myGroup.group
				}}
			</section>
			<section *ngIf="!menuIsExpanded" class="h-2.5 main-menu-separator">&nbsp;</section>

			<div *ngFor="let myItem of myGroup.items" class="group-level1">

				<!-- item principal (nivel 1) -->
				<app-menu-item (submenuClicked)="toggleSubItems($event)" [HasSubItems]="myItem.subItems.length" [icon]="myItem.icon" [itemID]="myItem.itemID"
				               [itemLevel]="1" [label]="myItem.label" [link]="myItem.link"
				               [menuFontSize]="menuFontSize">
				</app-menu-item>

				<div *ngIf="shownSubItems.includes(myItem.itemID)">
					<div *ngFor="let mySubItem of myItem.subItems" class="bloque-level2">

						<!-- item nivel 2 -->
						<app-menu-item (submenuClicked)="toggleSubItems($event)" [HasSubItems]="mySubItem.subItems.length" [icon]="mySubItem.icon"
						               [itemID]="mySubItem.itemID"
						               [itemLevel]="2" [label]="mySubItem.label"
						               [link]="mySubItem.link">
						</app-menu-item>

						<div *ngIf="shownSubItems.includes(mySubItem.itemID)">
							<div *ngFor="let mySubSubItem of mySubItem.subItems" class="bloque-level3">

								<!-- item nivel 3 -->
								<app-menu-item (submenuClicked)="toggleSubItems($event)" [HasSubItems]="mySubSubItem.subItems.length"
								               [icon]="mySubSubItem.icon"
								               [itemID]="mySubSubItem.itemID" [itemLevel]="3"
								               [label]="mySubSubItem.label" [link]="mySubSubItem.link">
								</app-menu-item>

							</div>
						</div>

					</div>
				</div>

			</div>
		</div>
	</div>

	<div *ngIf="menuIsExpanded" class="fold-unfold-menu-button">
		<a (click)="unfoldMenu()">
            <span class="material-icons" style="font-size: 1.1rem;" title="Unfold menu items">
                unfold_more
            </span>
		</a>
		<a (click)="foldMenu()">
            <span class="material-icons" title="Fold menu items">
                unfold_less
            </span>
		</a>
	</div>


</div>
