import { RouteReuseStrategy, ActivatedRouteSnapshot, DetachedRouteHandle } from '@angular/router';

export class CustomReuseStrategy implements RouteReuseStrategy {
    storedRoutes: { [key: string]: DetachedRouteHandle } = {};

    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        return true; // Cache all routes
    }

    store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
        this.storedRoutes[route.routeConfig?.path || ''] = handle; // Store route and component instance
    }

    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        return !!this.storedRoutes[route.routeConfig?.path || '']; // Check if route exists in cache
    }

    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
        return this.storedRoutes[route.routeConfig?.path || '']; // Retrieve cached route
    }

    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        return future.routeConfig === curr.routeConfig; // Reuse the same route
    }
}