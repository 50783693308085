/* eslint-disable @typescript-eslint/no-explicit-any */
// src/app/interceptors/graphql-interceptor.fn.ts
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandlerFn,
    HttpInterceptorFn,
    HttpRequest,
    HttpResponse,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { CustomSnackbarComponent } from '../../shared/components/custom-snackbar/custom-snackbar.component';
import { GraphQLErrorHandlerService } from '../../shared/services/graphql-error-handler.service';
import { AuthService } from '../auth/auth.service';

// Helper function to format an array of error objects.
function formatErrors(errors: any[]): string {
    return errors
        .map((err) => {
            const fieldText =
                err.extensions && err.extensions.field ? `[${err.extensions.field}]: ` : '';
            return `- ${fieldText}"${err.message}"`;
        })
        .join('\n');
}

function showSuccessSnackbar(event: HttpResponse<any>, snackBar: MatSnackBar) {
    if (event.body && event.body.data) {
        const dataObj = event.body.data;
        const keys = Object.keys(dataObj);
        if (keys.length > 0) {
            const operationResponse = dataObj[keys[0]];
            if (operationResponse && operationResponse.success) {
                const title = 'networkSuccess.operationSuccessful';
                const message = 'networkSuccess.operationCompletedSuccessfully';
                snackBar.openFromComponent(CustomSnackbarComponent, {
                    data: { title, message, type: 'success' },
                    panelClass: ['custom-snackbar', 'custom-snackbar-success-shadow'],
                    duration: 3000,
                });
            }
        }
    }
}

export const graphqlInterceptor: HttpInterceptorFn = (
    req: HttpRequest<any>,
    next: HttpHandlerFn
): Observable<HttpEvent<any>> => {
    const snackBar = inject(MatSnackBar);
    const errorHandler = inject(GraphQLErrorHandlerService);
    const authService = inject(AuthService);
    const router = inject(Router);

    // Check if the request is a mutation.
    const isMutation =
        req.body && req.body.query && req.body.query.trim().toLowerCase().startsWith('mutation');

    return next(req).pipe(
        switchMap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse && event.body) {
                if (event.body.errors && event.body.errors.length > 0) {
                    errorHandler.handleErrorResponse(event.body);
                }
            }
            return of(event);
        }),
        // Use tap to show the success snackbar.
        tap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse && isMutation) {
                showSuccessSnackbar(event, snackBar);
            }
        }),
        // Global error handler.
        catchError((error: HttpErrorResponse) => {
            let errorMsg = '';
            if (error.error) {
                try {
                    const parsed =
                        typeof error.error === 'string' ? JSON.parse(error.error) : error.error;
                    if (parsed && parsed.errors && parsed.errors.length > 0) {
                        errorMsg = formatErrors(parsed.errors);

                        // Check if refresh is needed from the error side.
                        const shouldRefreshToken = parsed.errors.some(
                            (err: any) => err.extensions?.code === 'REFRESH_TOKEN'
                        );
                        if (shouldRefreshToken) {
                            return authService.fetchNewTokenFromServer().pipe(
                                switchMap((newToken) => {
                                    const clonedRequest = req.clone({
                                        headers: req.headers.set(
                                            'Authorization',
                                            `Bearer ${newToken}`
                                        ),
                                    });
                                    return next(clonedRequest);
                                }),
                                tap((event: HttpEvent<any>) => {
                                    if (event instanceof HttpResponse && isMutation) {
                                        showSuccessSnackbar(event, snackBar);
                                    }
                                }),
                                catchError((refreshError) => {
                                    authService.signOut();
                                    location.reload();
                                    return throwError(() => refreshError);
                                })
                            );
                        }

                        // Check if we should sign out.
                        const shouldSignOut = parsed.errors.some(
                            (err: any) => err.extensions?.code === 'REDIRECT_TO_LOGIN'
                        );
                        if (shouldSignOut) {
                            authService.signOut();

                            router.navigate(['/sign-in']).then(() => {
                                snackBar.openFromComponent(CustomSnackbarComponent, {
                                    data: {
                                        title: 'networkMessages.expiredSessionTitle',
                                        message: 'networkMessages.expiredSessionMessage',
                                        type: 'warning',
                                    },
                                    panelClass: [
                                        'custom-snackbar',
                                        'custom-snackbar-warning-shadow',
                                    ],
                                    duration: 5000,
                                });
                            });
                            return throwError(() => error);
                        }
                    } else {
                        errorMsg = error.message;
                    }
                } catch {
                    errorMsg = error.message;
                }
            } else {
                errorMsg = error.message;
            }

            snackBar.openFromComponent(CustomSnackbarComponent, {
                data: {
                    title: `[${error.status}]: Error`,
                    message: errorMsg,
                    type: 'error',
                },
                panelClass: ['custom-snackbar', 'custom-snackbar-error-shadow'],
            });

            return throwError(() => error);
        })
    );
};
