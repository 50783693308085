import { registerLocaleData } from '@angular/common';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import localeEs from '@angular/common/locales/es';
import {
    APP_INITIALIZER,
    ApplicationConfig,
    DEFAULT_CURRENCY_CODE,
    LOCALE_ID,
    inject,
} from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
    PreloadAllModules,
    provideRouter,
    withInMemoryScrolling,
    withPreloading,
} from '@angular/router';
import { InMemoryCache } from '@apollo/client/core';
import { provideFuse } from '@fuse';
import { TranslocoService, provideTransloco } from '@jsverse/transloco';
import { provideTranslocoPersistLang } from '@jsverse/transloco-persist-lang';
import { provideApollo } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { appRoutes } from 'app/app.routes';
import { provideAuth } from 'app/core/auth/auth.provider';
import { provideIcons } from 'app/core/icons/icons.provider';
import { mockApiServices } from 'app/mock-api';
import { Settings } from 'luxon';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { firstValueFrom } from 'rxjs';
import { environment } from '../environments/environment';
import { CustomMatPaginatorIntl } from './core/adapters/custom-mat-paginator-intl';
import { LuxonMondayAdapter } from './core/adapters/luxon-monday.adapter';
import { graphqlInterceptor } from './core/interceptors/graqphql.interceptor';
import { TranslocoHttpLoader } from './core/transloco/transloco.http-loader';
import { cacheTypePolicies } from './infrastructure/apollo-cache-policies';

registerLocaleData(localeEs, 'es');

export const appConfig: ApplicationConfig = {
    providers: [
        provideAnimations(),
        provideHttpClient(),
        provideApollo(() => {
            const httpLink = inject(HttpLink);
            return {
                link: httpLink.create({
                    uri: environment.apiUrl,
                    withCredentials: true,
                }),
                cache: new InMemoryCache({
                    typePolicies: cacheTypePolicies,
                }),
                defaultOptions: {
                    watchQuery: {
                        fetchPolicy: 'cache-and-network',
                    },
                    query: {
                        fetchPolicy: 'network-only',
                    },
                },
            };
        }),
        provideRouter(
            appRoutes,
            withPreloading(PreloadAllModules),
            withInMemoryScrolling({ scrollPositionRestoration: 'enabled' })
        ),
        provideHttpClient(withInterceptors([graphqlInterceptor])),
        {
            provide: DateAdapter,
            useClass: LuxonMondayAdapter,
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'dd/MM/yyyy',
                },
                display: {
                    dateInput: 'dd/MM/yyyy',
                    monthYearLabel: 'LLL yyyy',
                    dateA11yLabel: 'DD',
                    monthYearA11yLabel: 'LLLL yyyy',
                },
            },
        },
        { provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl },
        provideTransloco({
            config: {
                availableLangs: [
                    { id: 'en', label: 'English' },
                    { id: 'es', label: 'Español' },
                ],
                defaultLang: 'es',
                fallbackLang: 'en',
                reRenderOnLangChange: true,
                prodMode: environment.production,
            },
            loader: TranslocoHttpLoader,
        }),
        provideTranslocoPersistLang({
            storage: { useValue: localStorage },
        }),
        {
            provide: APP_INITIALIZER,
            useFactory: () => {
                const translocoService = inject(TranslocoService);
                const defaultLang = translocoService.getDefaultLang();
                Settings.defaultLocale = defaultLang;
                Settings.defaultZone = 'utc';

                return () =>
                    firstValueFrom(translocoService.load(defaultLang)).then(() => {
                        Promise.all([
                            firstValueFrom(
                                translocoService.load(`./global/countries/${defaultLang}`)
                            ).then((data) => {
                                translocoService.setTranslation(data, defaultLang, {
                                    merge: true,
                                });
                            }),

                            firstValueFrom(
                                translocoService.load(`./global/states/${defaultLang}`)
                            ).then((data) => {
                                translocoService.setTranslation(data, defaultLang, {
                                    merge: true,
                                });
                            }),

                            firstValueFrom(
                                translocoService.load(`./global/phone-prefixes/${defaultLang}`)
                            ).then((data) => {
                                translocoService.setTranslation(data, defaultLang, {
                                    merge: true,
                                });
                            }),
                        ]);
                    });
            },
            multi: true,
        },
        provideEnvironmentNgxMask({
            validation: true,
            dropSpecialCharacters: true,
            thousandSeparator: '.',
            decimalMarker: ',',
        }),
        { provide: LOCALE_ID, useValue: 'es' },
        { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' },
        provideAuth(),
        provideIcons(),
        provideFuse({
            mockApi: {
                delay: 0,
                services: mockApiServices,
            },
            fuse: {
                layout: 'dense',
                scheme: 'light',
                screens: {
                    xs: '0px',
                    sm: '600px',
                    md: '960px',
                    lg: '1280px',
                    xl: '1440px',
                },
                theme: 'theme-teal',
                themes: [
                    { id: 'theme-default', name: 'Default' },
                    { id: 'theme-brand', name: 'Brand' },
                    { id: 'theme-teal', name: 'Teal' },
                    { id: 'theme-rose', name: 'Rose' },
                    { id: 'theme-purple', name: 'Purple' },
                    { id: 'theme-amber', name: 'Amber' },
                ],
            },
        }),
    ],
};
