import { ResolveFn } from '@angular/router';
import {inject} from "@angular/core";
import {PersonService} from "../../person/person.service";
import {
  PersonCompanyAdditionalInformationService
} from "../../../services/person-company-additional-information.service";

export const personCompanyAiResolver: ResolveFn<boolean> = (route, state) => {
  console.log('personCompanyAiResolver', route.paramMap.get('id'));
  return inject(PersonCompanyAdditionalInformationService).get(route.paramMap.get('id'));
};
