import { ContactsMockApi } from 'app/mock-api/apps/contacts/api';
import { TasksMockApi } from 'app/mock-api/apps/tasks/api';
import { MessagesMockApi } from 'app/mock-api/common/messages/api';
import { NavigationMockApi } from 'app/mock-api/common/navigation/api';
import { NotificationsMockApi } from 'app/mock-api/common/notifications/api';
import { SearchMockApi } from 'app/mock-api/common/search/api';
import { ShortcutsMockApi } from 'app/mock-api/common/shortcuts/api';

export const mockApiServices = [
    ContactsMockApi,
    MessagesMockApi,
    NavigationMockApi,
    NotificationsMockApi,
    SearchMockApi,
    ShortcutsMockApi,
    TasksMockApi,
];
