/* eslint-disable @typescript-eslint/no-explicit-any */
import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { DateAdapter } from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';
import { AvailableLangs, TranslocoService } from '@jsverse/transloco';
import { Settings } from 'luxon';
import { distinctUntilChanged, firstValueFrom, from, switchMap } from 'rxjs';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'languages',
    templateUrl: './languages.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'languages',
    standalone: true,
    imports: [MatButtonModule, MatMenuModule, NgTemplateOutlet],
})
export class LanguagesComponent implements OnInit {
    availableLangs: AvailableLangs;
    activeLang: string;
    flagCodes = {
        en: 'gb',
        es: 'es',
    };

    /**
     * Constructor
     */
    constructor(
        private _translocoService: TranslocoService,
        private _dateAdapter: DateAdapter<any>
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Get the available languages from transloco
        this.availableLangs = this._translocoService.getAvailableLangs();

        this._translocoService.langChanges$
            .pipe(
                distinctUntilChanged(), // Evita ejecutar código si el idioma no cambia realmente
                switchMap((activeLang) => {
                    // Actualizar el idioma activo sin disparar cambios infinitos
                    this.activeLang = activeLang;
                    Settings.defaultLocale = activeLang;
                    this._dateAdapter.setLocale(activeLang);

                    // Convertimos la promesa en un observable para evitar el error de tipado
                    return from(this.loadAdditionalTranslations(activeLang));
                })
            )
            .subscribe();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Set the active lang
     *
     * @param lang
     */
    setActiveLang(lang: string): void {
        // Set the active lang
        this._translocoService.setActiveLang(lang);
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item) {
        return item.id || index;
    }

    private async loadAdditionalTranslations(lang: string): Promise<void> {
        await firstValueFrom(this._translocoService.load(lang));
        await Promise.all([
            firstValueFrom(this._translocoService.load(`./global/countries/${lang}`)).then(
                (data) => {
                    this._translocoService.setTranslation(data, lang, { merge: true });
                }
            ),

            firstValueFrom(this._translocoService.load(`./global/states/${lang}`)).then((data) => {
                this._translocoService.setTranslation(data, lang, { merge: true });
            }),

            firstValueFrom(this._translocoService.load(`./global/phone-prefixes/${lang}`)).then(
                (data) => {
                    this._translocoService.setTranslation(data, lang, { merge: true });
                }
            ),
        ]);
    }
}
