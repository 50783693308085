import { Route } from '@angular/router';
import { initialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { Error401Component } from './modules/error/error-401/error-401.component';
import { Error404Component } from './modules/error/error-404/error-404.component';

export const appRoutes: Route[] = [
    // Redirect empty path to 'operational/clients'
    { path: '', pathMatch: 'full', redirectTo: 'operational/clients' },

    // Redirect signed-in user to the 'operational/clients'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'operational/clients' },

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'confirmation-required',
                loadChildren: () =>
                    import('app/modules/auth/confirmation-required/confirmation-required.routes'),
            },
            {
                path: 'forgot-password',
                loadChildren: () =>
                    import('app/modules/auth/forgot-password/forgot-password.routes'),
            },
            {
                path: 'reset-password',
                loadChildren: () => import('app/modules/auth/reset-password/reset-password.routes'),
            },
            {
                path: 'sign-in',
                loadChildren: () => import('app/modules/auth/sign-in/sign-in.routes'),
            },
            {
                path: 'sign-up',
                loadChildren: () => import('app/modules/auth/sign-up/sign-up.routes'),
            },
        ],
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'sign-out',
                loadChildren: () => import('app/modules/auth/sign-out/sign-out.routes'),
            },
            {
                path: 'unlock-session',
                loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.routes'),
            },
        ],
    },

    // // Landing routes
    // {
    //     path: '',
    //     component: LayoutComponent,
    //     data: {
    //         layout: 'empty',
    //     },
    //     children: [
    //         { path: 'home', loadChildren: () => import('app/modules/landing/home/home.routes') },
    //     ],
    // },

    // Admin routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver,
        },
        children: [
            // Operativa
            {
                path: 'operational',
                children: [
                    {
                        path: 'calendar',
                        loadChildren: () =>
                            import('app/modules/admin/operational/calendar/calendar.routes'),
                    },
                    {
                        path: 'business-services',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/operational/business-services/business-services.routes'
                            ),
                    },
                    {
                        path: 'billing',
                        loadChildren: () =>
                            import('app/modules/admin/operational/billing/billing.routes'),
                    },
                    {
                        path: 'clients',
                        loadChildren: () =>
                            import('app/modules/admin/operational/clients/clients.routes'),
                    },
                    {
                        path: 'users',
                        loadChildren: () =>
                            import('app/modules/admin/operational/users/users.routes'),
                    },
                ],
            },
            // Sistema
            {
                path: 'system',
                children: [
                    {
                        path: 'settings',
                        loadChildren: () =>
                            import('app/modules/admin/system/settings/settings.routes'),
                    },
                ],
            },

            // Ruta para el Error 401
            {
                path: 'unauthorized', // Puedes usar cualquier nombre para la ruta
                component: Error401Component,
            },

            // Ruta 404 - DEBE SER LA ÚLTIMA EN ESTE BLOQUE
            {
                path: '**', // Captura todas las rutas no definidas
                component: Error404Component,
            },
        ],
    },

    // Test iframe book appointment

    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'book-appointment',
                loadChildren: () =>
                    import('app/modules/public/book-appointment/book-appointment.routes'),
            },
        ],
    },
];
