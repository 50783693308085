// custom-mat-paginator-intl.ts
import { inject } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslocoService } from '@jsverse/transloco';

export class CustomMatPaginatorIntl extends MatPaginatorIntl {
    private translocoService = inject(TranslocoService);

    constructor() {
        super();
        this.getTranslations();

        this.translocoService.langChanges$.subscribe(() => {
            this.getTranslations();
            this.changes.next();
        });
    }

    private getTranslations(): void {
        this.itemsPerPageLabel = this.translocoService.translate('paginator.itemsPerPageLabel');
        this.nextPageLabel = this.translocoService.translate('paginator.nextPageLabel');
        this.previousPageLabel = this.translocoService.translate('paginator.previousPageLabel');
        this.firstPageLabel = this.translocoService.translate('paginator.firstPageLabel');
        this.lastPageLabel = this.translocoService.translate('paginator.lastPageLabel');
        this.getRangeLabel = (page: number, pageSize: number, length: number): string => {
            if (length === 0 || pageSize === 0) {
                return `0 ${this.translocoService.translate('paginator.of')} ${length}`;
            }

            const startIndex = page * pageSize;
            const endIndex =
                startIndex < length
                    ? Math.min(startIndex + pageSize, length)
                    : startIndex + pageSize;

            return `${startIndex + 1} - ${endIndex} ${this.translocoService.translate('paginator.of')} ${length}`;
        };
    }
}
