import {Injectable} from '@angular/core';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root'
})
export class UserMessageService {

    constructor(
        private snackBar: MatSnackBar,
    ) {
    }

    public sm(message: string, panelClass = 'mat-toolbar') {
        this.showMessage(message, 5000, 'center', 'bottom', panelClass);
    }

    public showMessage(
        message: string,
        duration?: number,
        horizontalPosition?: MatSnackBarHorizontalPosition,
        verticalPosition?: MatSnackBarVerticalPosition,
        panelClass?: string,
    ) {

        this.snackBar.open(
            message,
            'X',
            {
                duration: duration ? duration : 5000,
                horizontalPosition: horizontalPosition ? horizontalPosition : 'center',
                verticalPosition: verticalPosition ? verticalPosition : 'bottom',
                panelClass: panelClass ? panelClass : ['mat-toolbar',
                    'mat-warn'],
            }
        );
    }

    public formErrors(message='The form contains has errors')
    {
        this.showMessage(
            message,
            3000,
            'center',
            'top',
            'mat-toolbar-warn'
        );
    }

    public formSuccess(message='Success!')
    {
        this.showMessage(
            message,
            3000,
            'center',
            'bottom',
            'mat-toolbar-success'
        );
    }

}
