<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation
    class="dark bg-gray-900 print:hidden"
    [appearance]="navigationAppearance"
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'"
    [navigation]="navigation.default"
    [opened]="!isScreenSmall"
>
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <!-- Logo -->
        <div class="flex h-20 items-center justify-center">
            <img class="w-8" src="images/logo/logo.svg" alt="Logo image" />
        </div>
    </ng-container>

    <ng-container fuseVerticalNavigationContentFooter>
        <div
            class="absolute bottom-0 my-2 flex h-16 w-full flex-0 flex-col items-center justify-center py-2"
        >
            <img class="max-w-18 opacity-15" src="images/logo/logo-text-on-dark.svg" />
            <p class="mr-1 w-full text-center text-xs font-medium opacity-50">
                Artek &copy;{{ currentYear }}
            </p>
        </div>
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex w-full min-w-0 flex-auto flex-col">
    <!-- Header -->
    <div
        class="bg-card relative z-49 flex h-16 w-full flex-0 items-center px-4 shadow dark:border-b dark:bg-transparent dark:shadow-none md:px-6 print:hidden"
    >
        <div class="flex items-center space-x-2 pr-2">
            <!-- Navigation toggle button -->
            <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
                <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
            </button>
            <!-- Navigation appearance toggle button -->
            <button
                class="hidden md:inline-flex"
                mat-icon-button
                (click)="toggleNavigationAppearance()"
            >
                <mat-icon [svgIcon]="'heroicons_outline:arrows-right-left'"></mat-icon>
            </button>
            <!-- Zoom in/out buttons -->
            <app-zoom-buttons class="hidden md:inline-flex"></app-zoom-buttons>
        </div>
        <!-- Components -->
        <div class="ml-auto flex items-center space-x-0.5 pl-2 sm:space-x-2">
            <languages></languages>
            <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
            <app-current-page-settings-button></app-current-page-settings-button>
            @if (showAdditionalFeatures) {
                <search [appearance]="'bar'"></search>
                <shortcuts></shortcuts>
                <messages></messages>
                <notifications></notifications>
            }
            <user></user>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-auto flex-col">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        @if (true) {
            <router-outlet></router-outlet>
        }
    </div>

    <!-- Footer -->
    <!-- <div
        class="bg-card relative z-49 flex h-14 w-full flex-0 items-center justify-start border-t px-4 dark:bg-transparent md:px-6 print:hidden"
    >
        <span class="text-secondary font-medium">Artek &copy; {{ currentYear }}</span>
    </div> -->
</div>
