<!-- <div style="z-index:999999; float:right">menuIsPinned:{{menuIsPinned}}</div> -->

<div class="main-template">

	<!--
	Posibles estados del sidenav:

		Pantalla grande (> 800px)
			pin pulsado
				sidenav.mode   = side
				sidenav.opened = true
				sidenav width  = 220px
			else
				mouseover (sidenav)
					sidenav.mode   = over
					sidenav.opened = true
					sidenav width  = 220px
				else
					sidenav.mode   = side
					sidenav.opened = true
					sidenav width  = 50px

		Pantalla pequeña
				sidenav.mode   = over
				sidenav width  = 220px

	Mode	Description
	over	Sidenav floats over the primary content, which is covered by a backdrop
	push	Sidenav pushes the primary content out of its way, also covering it with a backdrop
	side	Sidenav appears side-by-side with the main content, shrinking the main content's width to make space for the sidenav.
-->
	<mat-sidenav-container>
		<!--"we add a 'template reference variable' 'sidenav' and it will export as 'matSidenav' component"-->
		<mat-sidenav #sidenav="matSidenav"
                     [fixedInViewport]="true"
                     [mode]="sideNavCurrentConfig.mode"
                     [opened]="sideNavCurrentConfig.opened"
		             (mouseenter)="expandMenu()"
		             (mouseleave)="contractMenu()"
		             [ngClass]="{'unpinned-menu-mat-sidenav': !menuIsPinned, 'expanded-menu-mat-sidenav': menuIsExpanded, 'contracted-menu-mat-sidenav': !menuIsExpanded}" class="mat-elevation-z8 mat-sidenav-background-color-{{sidenavBackgroundColor}}">

			<div class="menu-content">
				<div class="menu-top">

					<div class="brand-logo">
						<a routerLink="/login/"><img src="/assets/img/logos/logo-14all.svg"/></a>

					</div>
					<div class="brand-name">
						CLINITEK
					</div>

					<div class="pin-menu-button">
						<button (click)="togglePinMenu()" *ngIf="!menuIsPinned" mat-button title="Unfold menu">
							<mat-icon>motion_photos_on</mat-icon>
						</button>
						<button (click)="togglePinMenu()" *ngIf="menuIsPinned" mat-button title="Minimize menu">
							<mat-icon>motion_photos_off</mat-icon>
						</button>
					</div>
				</div>

				<div class="main-menu-container">
					<app-main-menu [menuFontSize]="menuFontSize" [menuIsExpanded]="menuIsExpanded"></app-main-menu>
				</div>

				<div *ngIf="menuIsExpanded" class="menu-style">
					<a (click)="rollsidenavBackgroundColor()">
                        <span class="material-icons" title="Background color">
                            format_color_fill
                        </span>
					</a>

					<a (click)="rollsidenavBackgroundImage()">
                        <span class="material-icons" title="Background image">
                            image
                        </span>
					</a>

					<a (click)="zoomOutMenuFont()">
                        <span class="material-icons" title="Smaller fonts">
                            zoom_out
                        </span>
					</a>

					<a (click)="zoomInMenuFont()">
                        <span class="material-icons bigger" title="Bigger fonts">
                            zoom_in
                        </span>
					</a>
				</div>

			</div>

			<div [ngClass]="'sidenav-background-image-' + sidenavBackgroundImage" class="sidenav-background"></div>

		</mat-sidenav>

		<mat-sidenav-content [ngClass]="{'unpinned-menu-mat-sidenav-content': !menuIsPinned}">

			<app-header (menuButtonClicked)="mainMenuToggle()" [sidenavOpened]="sidenav.opened" [currentScreenSize]="sideNavCurrentConfig.size"></app-header>

			<div class="central-container">
				<router-outlet></router-outlet>
			</div>

			<app-footer></app-footer>

		</mat-sidenav-content>

	</mat-sidenav-container>

</div>
