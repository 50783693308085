import { Injectable, signal } from '@angular/core';

type ZoomLevel = number & { _type?: 'ZoomLevel' };

@Injectable({
    providedIn: 'root',
})
export class ZoomConfigService {
    zoom = signal<ZoomLevel>(1);

    maxZoom = 1.5;
    minZoom = 0.8;

    step = 0.1;

    constructor() {
        const storedZoom = localStorage.getItem('zoom');
        if (storedZoom !== null) {
            this.setZoom(parseFloat(storedZoom));
        }
    }

    // Función para asegurar que el zoom está dentro del rango permitido
    private clampZoom(value: number): ZoomLevel {
        return Math.max(this.minZoom, Math.min(this.maxZoom, value)) as ZoomLevel;
    }

    // Establecer un nuevo zoom asegurando los límites
    setZoom(newZoom: number): void {
        const clampedZoom = this.clampZoom(newZoom);
        this.zoom.set(clampedZoom);
        localStorage.setItem('zoom', clampedZoom.toString());
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (document.body.style as any).zoom = clampedZoom.toString();
    }

    // Aumentar el zoom
    zoomIn(increment = this.step): void {
        this.setZoom(this.zoom() + increment);
    }

    // Disminuir el zoom
    zoomOut(decrement = this.step): void {
        this.setZoom(this.zoom() - decrement);
    }
}
