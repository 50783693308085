import {inject, Injectable} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {FormConfigInterface} from './form-config/form-config-interface';
import {FieldInterface} from './form-config/field-interface';
import {validOptionValidator} from './validators/valid-option-validator';
import {patternNameValidator} from './validators/pattern-name-validator';
import {StringDictionary} from '../../../general/classifications';
import {dateBeforeTodayValidator} from './validators/date-before-today-validator';
import {dateFormatValidator} from './validators/date-format-validator';
import {from, Observable} from 'rxjs';
import {identificationValidator} from "./validators/identification-validator";
import {serverErrorValidator} from "./validators/server-error";
import {
    identificationInUseAsyncValidator
} from "../../person/person-form-new/validators/Identification-validator";
import {PersonService} from "../../person/person.service";
import {postalCodeValidator} from "./validators/postal-code-validator";
import {PersonsService} from "../../../services/persons.service";
import {cifValidator} from "./validators/cif-validator";
import {websiteValidator} from "./validators/webstite-validator";
import {spanishPhoneValidator} from "./validators/phone-number-validator";

export enum FieldIdentificationType {
    section = 'section',
    array = 'array'
}

export interface FieldGroupIdentificatorInterface {
    identificationBy: FieldIdentificationType;
    groupName: string;
}

const validatorMapping = {
    'required': () => Validators.required,
    'pattern': (val) => val.translationKey === 'pattern_name' ? patternNameValidator() : Validators.pattern(val.translationKey),
    'minlength': (val) => Validators.minLength(val.params?.requiredLength),
    'maxlength': (val) => Validators.maxLength(val.params?.requiredLength),
    'email': () => Validators.email,
    'invalidOption': (val, options) => validOptionValidator(options),
    'dateBeforeToday': () => dateBeforeTodayValidator(),
    'dateFormat': () => dateFormatValidator(),
    'document': () => identificationValidator(),
    'serverError': (val) => serverErrorValidator(val),
    'postalCode': () => postalCodeValidator(),
    'cif': () => cifValidator(),
    'website': () => websiteValidator(),
    'invalidPhone': () => spanishPhoneValidator(),
    /** ASYNC VALIDATORS */
    'identificationInUse': (personService: PersonService) => identificationInUseAsyncValidator(personService)
};



@Injectable({
    providedIn: 'root'
})

export class FormGeneratorService {
    public isOriginal = true;
    protected group: FormGroup;
    private formIsEnabled = true;
    private personService: PersonService = inject(PersonService);
    constructor(private fb: FormBuilder) {
        this.group = this.fb.group({});
    }

    get groupForm(): FormGroup {
        return this.group;
    }



    // form-generator.service.ts
    generate(formConfig: FormConfigInterface, initialData: any = {}, formIsEnabled: boolean = true, group?: FormGroup): Observable<FormGroup> {
        this.formIsEnabled = formIsEnabled;
        this.group = this.fb.group({});
        if (group) {
            this.group = group;
        }
        if (!formConfig) {
            throw new Error('Form configuration is required');
        }

        return this.createFormObservable(formConfig, initialData);
    }


    public addSection(sectionName: string, formConfig: FormConfigInterface, initialData: any = {}): void {
        if (!sectionName || !formConfig) {
            throw new Error('Section name and form configuration are required');
        }
        if (this.group.get(sectionName)) {
            return;
        }
        const identifiedBy: FieldGroupIdentificatorInterface = {
            identificationBy: FieldIdentificationType.section,
            groupName: sectionName
        };

        this.createFormGroup(formConfig, initialData, identifiedBy).subscribe(
            (formGroup) => {
                this.group.addControl(sectionName, formGroup);
            },
            (error) => {

            }
        );
    }

    public removeSection(sectionName: string): void {
        if (this.group.get(sectionName)) {
            this.group.removeControl(sectionName);
        }
    }

    public createFormGroup(formConfig: FormConfigInterface, initialData: any, fieldGroupIdentification?: FieldGroupIdentificatorInterface): Observable<FormGroup> {
        return from(new Promise<FormGroup>((resolve) => {
            const formGroup = this.fb.group({}) as FormGroup<any>;
            const filterFields = (formFieldConfig: FieldInterface) => {
                if (fieldGroupIdentification) {
                    const {identificationBy, groupName} = fieldGroupIdentification;
                    if (identificationBy in formFieldConfig && formFieldConfig[identificationBy] === groupName) {
                        return !(identificationBy === FieldIdentificationType.section && FieldIdentificationType.array in formFieldConfig);
                    }
                }
                return !fieldGroupIdentification && !(FieldIdentificationType.section in formFieldConfig) && !(FieldIdentificationType.array in formFieldConfig);
            };

            const formGroupFieldsConfig = Object.values(formConfig.fields).filter(filterFields);

            formGroupFieldsConfig.forEach(field => {

                formGroup.addControl(field.name, this.createFieldControl(field, initialData[field.name] ?? ''));
            });

            resolve(formGroup);
        }));
    }

    private createFormObservable(formConfig: FormConfigInterface, initialData: any): Observable<FormGroup> {
        return new Observable<FormGroup>((observer) => {
            this.createForm(formConfig, initialData)
                .then((form) => {
                    observer.next(form);
                    observer.complete();
                })
                .catch((error) => {
                    observer.error(error);
                });
        });
    }

    private async createForm(config: FormConfigInterface, initData: any): Promise<FormGroup> {
        const sectionGroups: { [key: string]: FormGroup } = {};

        const defaultFormGroup = await this.createFormGroup(config, initData).toPromise();

        if (Object.keys(defaultFormGroup.controls).length > 0) {
            sectionGroups['default'] = defaultFormGroup;
        }

        for (const section of config.sections || []) {
            const identifiedBy: FieldGroupIdentificatorInterface = {
                identificationBy: FieldIdentificationType.section,
                groupName: section
            };
            sectionGroups[section] = await this.createFormGroup(config, initData, identifiedBy).toPromise();
        }

        for (const formArrayConfig of Object.values(config.arrays)) {
            sectionGroups[formArrayConfig.section].addControl(formArrayConfig.name, this.fb.array([]));
        }

        for (const sectionName of Object.keys(sectionGroups)) {
            if (Object.keys(sectionGroups[sectionName].controls).length > 0) {
                this.group.addControl(sectionName, sectionGroups[sectionName]);
            }
        }

        let cont = 0;
        for (const formArrayConfig of Object.values(config.arrays)) {

            const array = this.arrayName(formArrayConfig.section, formArrayConfig.name);

            const identifiedBy: FieldGroupIdentificatorInterface = {
                identificationBy: FieldIdentificationType.array,
                groupName: formArrayConfig.name
            };

            const arrayData = initData[formArrayConfig.name] || {};
            if (Array.isArray(arrayData)) {
                if (arrayData.length) {
                    for (const data of arrayData) {
                        const formGroup = await this.createFormGroup(config, data, identifiedBy).toPromise();
                        array.push(formGroup);
                    }
                } else {
                    const formGroup = await this.createFormGroup(config, arrayData, identifiedBy).toPromise();
                    array.push(formGroup);
                }
            } else {
                const formGroup = await this.createFormGroup(config, arrayData[cont] || {}, identifiedBy).toPromise();
                array.push(formGroup);
            }


            cont++;
        }

        return this.group;
    }

    private arrayName(section: string, arrayName: string): FormArray<any> {
        return this.group.get(section + '.' + arrayName) as FormArray<any>;
    }

    private createFieldControl(field: FieldInterface, fieldData: any): FormControl {
        const validators = this.mapValidators(field.validators, field.options);
        const asyncValidators = this.mapAsyncValidators(field, field.options);
        let value = fieldData ?? '';
        if ( field.defaultValue !== undefined) {
            value = field.defaultValue;
        }
        const state = {
            value: value,
            disabled: field.formIsEnabled !== undefined ? !field.formIsEnabled : !this.formIsEnabled
        };


        //const asyncValidators = this.createAsyncValidators(field) || [];
        //console.log('asyncValidators', asyncValidators);
        const control = new FormControl(state,
            {
                    validators: validators,
                    asyncValidators: asyncValidators,
            updateOn: 'change' });// Update validation status on blur
        //control.addAsyncValidators(        asyncValidators);
        return control;//this.fb.control(state, validators, asyncValidators);
    }

    private mapValidators(validators: any[], options: StringDictionary): any[] {
        if(!validators) {
            return;
        }
        return validators?.filter(val => val?.async !== true)
                .map(val => validatorMapping[val.validator](val, options)) || [];
    }

    private mapAsyncValidators(field: FieldInterface, options: StringDictionary): any[] {
        if(!field.validators) {
            return;
        }
        return field.validators?.filter(val => val?.async === true)
            .map(val => validatorMapping[val.validator](this.personService)) || [];
            //.map(val => val.func()) || [];
    }

    disableForm(form: FormGroup<any>, formConfig: FormConfigInterface) {
        form.disable();
    }

    enableForm(form: FormGroup<any>, formConfig: FormConfigInterface) {
        form.enable();
    }

    setServerErrors(form: FormGroup, errors: any[]) {
        for( const error of errors){
            for ( const section of Object.keys(form.controls)) {
                const sectionGroup = form.controls[section] as FormGroup;
                for (const field of Object.keys(sectionGroup.controls)) {
                    if (field === error.field) {
                        sectionGroup.get(field).setErrors({[`${error.type}`] : ''});
                    }
                }
            }
        }
    }
}
