import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'operational',
        title: 'navigation.sections.operational',
        // subtitle: 'Unique dashboard designs',
        type: 'group',
        icon: 'heroicons_outline:home',
        children: [
            {
                id: 'operational.calendar',
                title: 'navigation.menu.calendar',
                type: 'basic',
                icon: 'heroicons_solid:calendar',
                link: '/operational/calendar',
            },
            {
                id: 'operational.clients',
                title: 'navigation.menu.clients',
                type: 'basic',
                icon: 'mat_solid:face',
                link: '/operational/clients',
            },
            {
                id: 'operational.business-services',
                title: 'navigation.menu.services',
                type: 'basic',
                icon: 'heroicons_solid:briefcase',
                link: '/operational/business-services',
            },
            {
                id: 'operational.billing',
                title: 'navigation.menu.billing',
                type: 'collapsable',
                icon: 'heroicons_solid:document-text',
                children: [
                    {
                        id: 'operational.billing',
                        title: 'navigation.menu.invoices',
                        type: 'basic',
                        link: '/operational/billing/invoices',
                    },
                    {
                        id: 'operational.billing.budgets',
                        title: 'navigation.menu.budgets',
                        type: 'basic',
                        link: '/operational/billing/budgets',
                    },
                ],
            },
        ],
    },
    {
        id: 'system',
        title: 'navigation.sections.system',
        type: 'group',
        icon: 'heroicons_outline:adjustments-vertical',
        children: [
            {
                id: 'operational.user',
                title: 'navigation.menu.users',
                type: 'basic',
                icon: 'heroicons_solid:lock-closed',
                link: '/operational/users',
            },
            {
                id: 'system.settings',
                title: 'navigation.menu.settings',
                type: 'basic',
                icon: 'heroicons_solid:cog-6-tooth',
                link: '/system/settings',
            },
        ],
    },
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id: 'operational',
        title: 'navigation.sections.operational',
        tooltip: 'navigation.sections.operational',
        type: 'aside',
        icon: 'heroicons_outline:home',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id: 'system',
        title: 'navigation.sections.system',
        tooltip: 'navigation.sections.system',
        type: 'aside',
        icon: 'heroicons_outline:adjustments-vertical',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: 'operational',
        title: 'navigation.sections.operational',
        type: 'group',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id: 'system',
        title: 'navigation.sections.system',
        type: 'group',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: 'operational',
        title: 'navigation.sections.operational',
        type: 'group',
        icon: 'heroicons_outline:home',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id: 'system',
        title: 'navigation.sections.system',
        type: 'group',
        icon: 'heroicons_outline:adjustments-vertical',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
];
