
import { AbstractControl, ValidatorFn } from '@angular/forms';

export function spanishPhoneValidator(): ValidatorFn {
    const regex = /^[6789]\d{8}$/;
    return (control: AbstractControl): { [key: string]: any } | null => {
        if (!control.value) {
            return null; // if the control is empty, we assume it's valid (use required validator separately if needed)
        }

        const valid = regex.test(control.value.trim());
        return valid ? null : { 'invalidPhone': { value: control.value } };
    };
}