import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ZoomConfigService } from 'app/shared/services/zoom-config.service';

@Component({
    selector: 'app-zoom-buttons',
    standalone: true,
    imports: [CommonModule, MatButtonModule, MatIconModule, MatTooltipModule],
    templateUrl: './zoom-buttons.component.html',
})
export class ZoomButtonsComponent {
    private zoomConfigService = inject(ZoomConfigService);

    zoomIn() {
        this.zoomConfigService.zoomIn();
    }

    zoomOut() {
        this.zoomConfigService.zoomOut();
    }
}
