import { Injectable } from '@angular/core';
import { LuxonDateAdapter } from '@angular/material-luxon-adapter';

@Injectable()
export class LuxonMondayAdapter extends LuxonDateAdapter {
    /**
     * Sobrescribe el primer día de la semana para que sea lunes
     */
    override getFirstDayOfWeek(): number {
        return 1; // 1 corresponde a lunes
    }
}
