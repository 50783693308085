<div class="absolute inset-0">
    <!-- Main -->
    <div class="flex h-full flex-auto flex-col items-center justify-center p-6 sm:p-10">
        <!-- prettier-ignore -->
        <div class="w-full max-w-3xl">



<!-- Generator: Adobe Illustrator 27.5.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 500 500" style="enable-background:new 0 0 500 500;" xml:space="preserve">
	 
<g id="Background_Complete">
	<g>
		<rect y="382.398" style="fill:#EBEBEB;" width="500" height="0.25"/>
		<rect x="78.545" y="390.788" style="fill:#EBEBEB;" width="33.122" height="0.25"/>
		<rect x="133.333" y="398" style="fill:#EBEBEB;" width="30.167" height="0.25"/>
		<rect x="56.854" y="400.074" style="fill:#EBEBEB;" width="38.251" height="0.25"/>
		<rect x="434" y="399.528" style="fill:#EBEBEB;" width="15.901" height="0.25"/>
		<rect x="391.471" y="399.528" style="fill:#EBEBEB;" width="39.321" height="0.25"/>
		<rect x="250" y="390.663" style="fill:#EBEBEB;" width="30.333" height="0.25"/>
		<rect x="284.667" y="390.663" style="fill:#EBEBEB;" width="72.095" height="0.25"/>
		<path style="fill:#EBEBEB;" d="M237.014,337.8H43.915c-3.147,0-5.708-2.561-5.708-5.708V60.66c0-3.147,2.561-5.708,5.708-5.708
			h193.099c3.146,0,5.707,2.561,5.707,5.708v271.432C242.721,335.239,240.16,337.8,237.014,337.8z M43.915,55.203
			c-3.01,0-5.458,2.448-5.458,5.458v271.432c0,3.01,2.448,5.458,5.458,5.458h193.099c3.009,0,5.457-2.448,5.457-5.458V60.66
			c0-3.009-2.448-5.458-5.457-5.458H43.915z"/>
		<path style="fill:#EBEBEB;" d="M453.31,337.8H260.212c-3.147,0-5.707-2.561-5.707-5.708V60.66c0-3.147,2.561-5.708,5.707-5.708
			H453.31c3.148,0,5.708,2.561,5.708,5.708v271.432C459.019,335.239,456.458,337.8,453.31,337.8z M260.212,55.203
			c-3.009,0-5.457,2.448-5.457,5.458v271.432c0,3.01,2.448,5.458,5.457,5.458H453.31c3.01,0,5.458-2.448,5.458-5.458V60.66
			c0-3.009-2.448-5.458-5.458-5.458H260.212z"/>
	</g>
	<g>
		<g>
			
				<rect x="340.186" y="199.199" transform="matrix(0.7071 0.7071 -0.7071 0.7071 314.2608 -160.6245)" style="fill:#EBEBEB;" width="21.672" height="199.671"/>
			<polygon style="fill:#E0E0E0;" points="361.931,303.448 346.607,288.124 325.718,309.013 339.771,331.418 			"/>
			
				<rect x="357.626" y="209.415" transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 397.998 783.3564)" style="fill:#E0E0E0;" width="7.224" height="199.671"/>
			
				<rect x="340.186" y="204.307" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -112.2491 337.2923)" style="fill:#EBEBEB;" width="21.672" height="199.671"/>
			
				<rect x="357.626" y="194.091" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 824.5084 246.3343)" style="fill:#E0E0E0;" width="7.224" height="199.671"/>
		</g>
		<g>
			
				<rect x="106.857" y="239.821" transform="matrix(0.7071 0.7071 -0.7071 0.7071 258.2875 11.4772)" style="fill:#EBEBEB;" width="16.866" height="155.396"/>
			<polygon style="fill:#E0E0E0;" points="123.78,320.954 111.854,309.028 95.597,325.285 106.534,342.722 			"/>
			
				<rect x="120.429" y="247.772" transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 -19.7576 642.7559)" style="fill:#E0E0E0;" width="5.622" height="155.396"/>
			
				<rect x="106.857" y="243.797" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -193.5634 175.6856)" style="fill:#EBEBEB;" width="16.866" height="155.396"/>
			
				<rect x="120.429" y="235.846" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 432.0933 448.1087)" style="fill:#E0E0E0;" width="5.622" height="155.396"/>
		</g>
		<g>
			<g>
				<rect x="301.262" y="275.115" style="fill:#F5F5F5;" width="17.606" height="107.284"/>
				<polygon style="fill:#EBEBEB;" points="318.868,319.695 301.262,319.695 301.262,332.561 318.868,328.757 				"/>
				
					<rect x="318.868" y="275.115" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 643.605 657.5133)" style="fill:#EBEBEB;" width="5.869" height="107.284"/>
			</g>
			<g>
				<rect x="116.786" y="275.115" style="fill:#F5F5F5;" width="17.606" height="107.284"/>
				<polygon style="fill:#EBEBEB;" points="134.392,319.695 116.786,319.695 116.786,332.561 134.392,328.757 				"/>
				
					<rect x="134.392" y="275.115" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 274.6532 657.5133)" style="fill:#EBEBEB;" width="5.869" height="107.284"/>
			</g>
			<g>
				<rect x="103.361" y="290.835" style="fill:#F5F5F5;" width="224.751" height="33.021"/>
				
					<rect x="328.113" y="290.835" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 659.5233 614.6917)" style="fill:#EBEBEB;" width="3.298" height="33.021"/>
			</g>
			<polygon style="fill:#FAFAFA;" points="242.731,257.173 198.792,257.173 167.722,288.243 167.722,332.183 198.792,363.253 
				242.731,363.253 273.801,332.183 273.801,288.243 			"/>
			<polygon style="fill:#E0E0E0;" points="240.722,262.023 200.801,262.023 172.571,290.252 172.571,330.174 200.801,358.403 
				240.722,358.403 268.952,330.174 268.952,290.252 			"/>
			<g>
				<path style="fill:#FFFFFF;" d="M182.189,314.819l5.622-0.501c0.121,1.3,0.369,2.29,0.743,2.97
					c0.608,1.102,1.477,1.653,2.608,1.653c0.843,0,1.493-0.281,1.95-0.846c0.457-0.563,0.685-1.216,0.685-1.958
					c0-0.706-0.218-1.337-0.652-1.894c-0.435-0.558-1.444-1.083-3.026-1.579c-2.591-0.828-4.438-1.93-5.542-3.305
					c-1.114-1.374-1.67-3.125-1.67-5.254c0-1.399,0.285-2.72,0.854-3.965c0.57-1.243,1.426-2.221,2.57-2.933
					c1.143-0.712,2.71-1.069,4.702-1.069c2.443,0,4.306,0.647,5.589,1.941c1.282,1.294,2.045,3.352,2.288,6.173l-5.568,0.465
					c-0.148-1.226-0.459-2.117-0.933-2.675c-0.474-0.556-1.128-0.835-1.963-0.835c-0.687,0-1.204,0.207-1.552,0.622
					c-0.348,0.415-0.522,0.918-0.522,1.513c0,0.432,0.144,0.822,0.431,1.169c0.278,0.358,0.939,0.693,1.983,1.003
					c2.582,0.792,4.432,1.594,5.549,2.405c1.117,0.811,1.93,1.817,2.439,3.018c0.508,1.201,0.763,2.543,0.763,4.028
					c0,1.746-0.339,3.355-1.017,4.828c-0.678,1.474-1.627,2.59-2.844,3.352c-1.217,0.761-2.751,1.141-4.603,1.141
					c-3.253,0-5.504-0.891-6.756-2.673C183.063,319.832,182.354,317.566,182.189,314.819z"/>
				<path style="fill:#FFFFFF;" d="M201.166,296.603h17.959v6.722h-6.026v20.499h-5.908v-20.499h-6.026V296.603z"/>
				<path style="fill:#FFFFFF;" d="M220.142,310.233c0-4.445,0.869-7.904,2.608-10.381c1.739-2.475,4.16-3.714,7.264-3.714
					c3.183,0,5.634,1.216,7.356,3.649c1.722,2.433,2.582,5.84,2.582,10.222c0,3.181-0.376,5.79-1.129,7.827
					c-0.752,2.037-1.839,3.621-3.26,4.753c-1.422,1.133-3.193,1.699-5.314,1.699c-2.156,0-3.941-0.489-5.354-1.466
					c-1.413-0.978-2.559-2.526-3.436-4.643C220.58,316.064,220.142,313.414,220.142,310.233z M226.05,310.269
					c0,2.749,0.358,4.723,1.076,5.924c0.717,1.201,1.693,1.801,2.928,1.801c1.269,0,2.252-0.588,2.948-1.763
					c0.695-1.176,1.044-3.286,1.044-6.332c0-2.562-0.363-4.435-1.089-5.617c-0.726-1.182-1.711-1.773-2.954-1.773
					c-1.191,0-2.148,0.6-2.87,1.801C226.41,305.51,226.05,307.497,226.05,310.269z"/>
				<path style="fill:#FFFFFF;" d="M243.109,296.603h9.821c2.139,0,3.741,0.725,4.806,2.172c1.065,1.449,1.598,3.51,1.598,6.183
					c0,2.749-0.581,4.897-1.741,6.444c-1.161,1.548-2.933,2.321-5.315,2.321h-3.235v10.101h-5.935V296.603z M249.044,308.208h1.448
					c1.139,0,1.939-0.281,2.4-0.845c0.46-0.563,0.691-1.284,0.691-2.163c0-0.853-0.2-1.578-0.6-2.172
					c-0.4-0.594-1.152-0.891-2.256-0.891h-1.683V308.208z"/>
			</g>
		</g>
		<path style="fill:#F0F0F0;" d="M411.347,102.747c-1.03,0-2.067,0.129-3.096,0.374c-0.004,0.001-0.009,0.002-0.013,0.004
			c-1.316-2.951-4.247-4.872-8.203-4.872c-2.897,0-5.857,1.027-8.436,2.735l0,0c-1.678-5.634-6.708-9.404-13.734-9.404
			c-9.986,0-20.447,7.632-24.228,17.392c-0.878-0.202-1.814-0.313-2.813-0.313c-0.313,0-0.627,0.011-0.941,0.031
			c-8.616,0.571-7.969,13.572,0.666,13.572h61.4c2.155,0,4.203-1.007,5.471-2.749c1.062-1.459,1.886-3.068,2.385-4.76
			C421.764,108.123,417.974,102.747,411.347,102.747z"/>
		<path style="fill:#F0F0F0;" d="M171.359,45.129c-1.341-8.879-8.42-15.07-18.771-15.07c-8.481,0-17.218,4.154-23.723,10.545
			c-0.002,0.002-0.004,0.004-0.006,0.006c-1.681-1.534-4.02-2.447-6.848-2.447c-7.178,0-14.713,5.819-16.832,12.996
			c-0.333,1.13-0.496,2.219-0.533,3.264c-0.005,0.003-0.01,0.007-0.015,0.01c-0.049,0.033-0.098,0.066-0.146,0.099
			c-5.945,4.043-2.958,13.385,4.232,13.385h63.231c2.447,0,4.775-1.189,6.104-3.243c0.989-1.529,1.768-3.173,2.273-4.886
			C182.582,52.136,178.606,45.845,171.359,45.129z"/>
		<path style="fill:#F5F5F5;" d="M246.884,144.296c-1.844-12.208-11.576-20.72-25.809-20.72c-11.66,0-23.674,5.711-32.617,14.498
			c-0.003,0.003-0.005,0.005-0.008,0.008c-2.311-2.109-5.528-3.364-9.416-3.364c-9.869,0-20.23,8-23.142,17.869
			c-0.458,1.553-0.682,3.051-0.733,4.488c-0.007,0.005-0.014,0.009-0.021,0.013c-0.067,0.045-0.134,0.09-0.201,0.136
			c-8.182,5.565-4.124,18.403,5.771,18.403h83.321c6.914,0,13.153-4.432,15.146-11.053c0.012-0.041,0.025-0.083,0.037-0.124
			C262.316,153.931,256.849,145.282,246.884,144.296z"/>
	</g>
</g>
<g id="Background_Simple" style="display:none;">
	<g style="display:inline;">
		<path class="text-primary" style="fill:currentColor;" d="M312.63,195.478c-16.424-28.295-22.446-56.696-48.459-80.939
			c-20.391-19.004-44.801-36.364-80.735-36.049c-34.237,0.299-66.685,11.37-81.895,37.85c-15.031,26.168-12.386,61.452,1.454,84.648
			c7.376,12.362,20.103,21.206,30.93,31.538c11.94,11.394,17.783,24.454,21.425,39.256c6.396,25.996,9.831,51.906,37.977,68.233
			c27.571,15.994,63.867,13.722,98.977,11.881c32.105-1.684,78.098,3.508,101.174-22.134c21.826-24.253,16.347-63.719-3.388-83.319
			C368.11,224.611,328.379,222.61,312.63,195.478z"/>
		<path style="opacity:0.9;fill:#FFFFFF;" d="M312.63,195.478c-16.424-28.295-22.446-56.696-48.459-80.939
			c-20.391-19.004-44.801-36.364-80.735-36.049c-34.237,0.299-66.685,11.37-81.895,37.85c-15.031,26.168-12.386,61.452,1.454,84.648
			c7.376,12.362,20.103,21.206,30.93,31.538c11.94,11.394,17.783,24.454,21.425,39.256c6.396,25.996,9.831,51.906,37.977,68.233
			c27.571,15.994,63.867,13.722,98.977,11.881c32.105-1.684,78.098,3.508,101.174-22.134c21.826-24.253,16.347-63.719-3.388-83.319
			C368.11,224.611,328.379,222.61,312.63,195.478z"/>
	</g>
</g>
<g id="Shadow_1_">
	<ellipse id="_x3C_Path_x3E__371_" style="fill:#F5F5F5;" cx="250" cy="416.238" rx="193.889" ry="11.323"/>
</g>
<g id="_x34__1">
	<path style="fill:#263238;" d="M128.743,128.923H78.545v-22.664l50.198-59.671h24.017v60.955h12.448v21.379H152.76v18.536h-24.017
		V128.923z M128.743,107.544V76.331l-26.524,31.213H128.743z"/>
	<path style="fill:#263238;" d="M364.91,46.588v100.871h-27.873V81.363c-4.511,3.428-8.874,6.202-13.091,8.321
		c-4.218,2.12-9.505,4.151-15.865,6.09V73.176c9.38-3.021,16.665-6.653,21.852-10.893c5.186-4.239,9.246-9.471,12.178-15.695H364.91
		z"/>
</g>
<g id="Character_1_">
	<g>
		<g>
			<path style="fill:#FF8B7B;" d="M311.817,173.193c-0.44,0.786-0.776,1.354-1.168,2.008c-0.379,0.632-0.777,1.242-1.171,1.855
				c-0.788,1.224-1.624,2.409-2.473,3.586c-1.712,2.344-3.533,4.622-5.512,6.803c-1.975,2.185-4.083,4.294-6.41,6.255
				c-2.313,1.974-4.829,3.816-7.669,5.377c-0.583,0.337-1.722,0.941-2.695,1.294c-1.001,0.377-1.999,0.654-2.977,0.833
				c-1.959,0.357-3.798,0.38-5.528,0.264c-3.457-0.244-6.49-1.14-9.338-2.162c-2.833-1.077-5.472-2.355-7.983-3.797
				c-2.525-1.463-4.852-2.986-7.203-4.861c-1.508-1.203-1.756-3.4-0.553-4.909c0.97-1.216,2.587-1.612,3.967-1.09l0.186,0.071
				c4.739,1.8,9.795,3.522,14.527,4.406c2.357,0.473,4.685,0.664,6.656,0.557c0.978-0.066,1.865-0.224,2.552-0.471
				c0.344-0.122,0.645-0.247,0.896-0.39c0.283-0.161,0.333-0.216,0.897-0.602c3.612-2.524,7.03-6.045,10.176-9.859
				c1.566-1.922,3.096-3.915,4.551-5.972c0.735-1.022,1.439-2.07,2.145-3.108l1.03-1.564l0.941-1.469l0.158-0.247
				c2.085-3.254,6.413-4.202,9.667-2.117C312.615,165.89,313.601,169.989,311.817,173.193z"/>
			<path style="fill:#FF8B7B;" d="M256.74,185.461l-7.67-5.076l-3.247,10.199c0,0,4.951,2.86,9.856-0.503L256.74,185.461z"/>
			<path style="fill:#FF8B7B;" d="M242.027,181.913l-0.599,3.245c-0.217,1.178,0.257,2.376,1.221,3.087l3.174,2.339l3.247-10.199
				l-3.083-0.906C244.206,178.955,242.363,180.088,242.027,181.913z"/>
			<g>
				<path class="text-primary" style="fill:currentColor;" d="M312.06,156.937c-7.809,1.237-15.711,10.407-20.459,18.954
					c5.221,9.095,13.533,12.417,13.533,12.417s9.26-8.763,13.011-15.234C321.897,166.602,319.407,155.773,312.06,156.937z"/>
				<path style="opacity:0.2;" d="M312.06,156.937c-7.809,1.237-15.711,10.407-20.459,18.954
					c5.221,9.095,13.533,12.417,13.533,12.417s9.26-8.763,13.011-15.234C321.897,166.602,319.407,155.773,312.06,156.937z"/>
			</g>
			<path style="fill:#263238;" d="M291.901,173.93c0,0-1.474,1.725-1.669,2.789c1.949,3.001,6.575,11.029,15.223,12.706
				c1.467-0.888,1.801-2.132,1.801-2.132S297.32,182.387,291.901,173.93z"/>
			<path class="text-primary" style="fill:currentColor;" d="M343.53,156.666c-9.197-1.495-19.781-1.942-28.566-0.816c-7.1,0.91-12.408,7.436-12.35,14.594
				c0.051,6.261,0.476,13.994,1.971,22.243c-2.608,13.757-3.546,32.438-0.796,36.559c0.001,0.004,49.196,0.696,49.196,0.696
				c2.104-5.887-0.541-31.327,2.265-57.898C356.035,164.612,350.908,157.865,343.53,156.666z"/>
			<path style="opacity:0.2;" d="M324.96,229.309c-2.807-22.131-0.031-59.143-0.003-59.514l0.997,0.076
				c-0.028,0.37-2.797,37.282-0.003,59.313L324.96,229.309z"/>
			<path style="fill:#263238;" d="M319.647,156.542c1.694-0.632,1.062-1.572,1.062-1.572s-7.682,0-12.991,1.432
				c-0.812,0.862,0.063,1.959,0.063,1.959S314.62,158.419,319.647,156.542z"/>
			<path style="opacity:0.2;" d="M322.789,155c0,0-3.167,5.861,1.073,11.19c-3.359,0.022-5.531,5.727-5.531,5.727
				s-6.563-9.119,1.833-16.411C320.631,154.784,322.789,155,322.789,155z"/>
			<path style="fill:#FFFFFF;" d="M319.405,157.841c-0.824,3.576,6.385,10.329,6.385,10.329s12.75-6.159,13.556-9.693
				L319.405,157.841z"/>
			<path style="opacity:0.2;" d="M348.179,178.79c-1.36,2.62,0.45,14.03,5.7,18.81c0.06-4.7,0.2-9.69,0.5-14.82L348.179,178.79z"/>
			<path style="fill:#FF8B7B;" d="M336.694,137.247l-13.164,8.638c1.558,3.826,2.286,7.807-2.212,9.644
				c-2.606,1.064-2.653,4.772-0.03,5.792c1.055,0.411,2.408,0.716,4.124,0.874c4.526,0.417,9.266-0.43,12.383-1.969
				c1.751-0.865,2.248-3.06,1.135-4.665C335.514,150.635,335.931,142.158,336.694,137.247z"/>
			<path style="opacity:0.2;" d="M331.327,140.772l-7.792,5.109c0.447,1.085,0.818,2.181,1.015,3.237
				c3.108-0.178,7.164-2.641,7.248-5.208C331.841,142.63,331.653,141.387,331.327,140.772z"/>
			<g>
				<path style="fill:#263238;" d="M322.076,120.772c3.356-0.068,4.266,4.742,3.038,6.366c2.539,0.553,3.765,3.524,3.034,5.032
					c-0.73,1.508-3.703,3.154-6.328,2.494c-4.486-1.128-5.265-4.854-4.036-6.553C315.345,126.096,316.566,120.026,322.076,120.772z"
					/>
				<path style="opacity:0.2;fill:#FFFFFF;" d="M322.076,120.772c3.356-0.068,4.266,4.742,3.038,6.366
					c2.539,0.553,3.765,3.524,3.034,5.032c-0.73,1.508-3.703,3.154-6.328,2.494c-4.486-1.128-5.265-4.854-4.036-6.553
					C315.345,126.096,316.566,120.026,322.076,120.772z"/>
			</g>
			<path style="fill:#FF8B7B;" d="M340.962,128.696c-1.006,7.893-1.193,12.568-5.466,16.339c-6.428,5.674-15.985,1.314-17.361-6.742
				c-1.238-7.251,0.614-18.941,8.587-21.519C334.578,114.235,341.968,120.803,340.962,128.696z"/>
			<g>
				<path style="fill:#263238;" d="M340.054,134.734c-3.357-0.049-4.097-4.888-2.814-6.468c-2.518-0.642-3.639-3.653-2.857-5.135
					c0.783-1.481,3.811-3.022,6.411-2.271c4.443,1.284,5.092,5.035,3.805,6.69C346.966,129.649,345.533,135.673,340.054,134.734z"/>
				<path style="opacity:0.2;fill:#FFFFFF;" d="M340.054,134.734c-3.357-0.049-4.097-4.888-2.814-6.468
					c-2.518-0.642-3.639-3.653-2.857-5.135c0.783-1.481,3.811-3.022,6.411-2.271c4.443,1.284,5.092,5.035,3.805,6.69
					C346.966,129.649,345.533,135.673,340.054,134.734z"/>
			</g>
			<path style="opacity:0.2;" d="M331.999,129.96c0,0,1.415-0.398,2.415,0.311C333.07,130.365,331.999,129.96,331.999,129.96z"/>
			<path style="opacity:0.2;" d="M331.964,130.54c0,0,1.338-0.087,2.049,0.819C332.81,131.164,331.964,130.54,331.964,130.54z"/>
			<path style="opacity:0.2;" d="M319.467,129.391c0,0-0.644-0.398-1.099,0.311C318.98,129.795,319.467,129.391,319.467,129.391z"/>
			<path style="opacity:0.2;" d="M319.484,129.971c0,0-0.609-0.087-0.932,0.819C319.099,130.595,319.484,129.971,319.484,129.971z"
				/>
			<path style="fill:#FF8B7B;" d="M343.383,136.739c-1.049,1.845-2.715,3.038-4.227,3.479c-2.275,0.663-3.185-1.613-2.361-3.981
				c0.741-2.132,2.818-5.045,5.01-4.66C343.964,131.956,344.583,134.629,343.383,136.739z"/>
			<path style="fill:#263238;" d="M330.405,130.017c-0.042,0.646-0.419,1.143-0.841,1.112s-0.73-0.58-0.688-1.226
				c0.042-0.646,0.419-1.144,0.841-1.112C330.139,128.823,330.447,129.371,330.405,130.017z"/>
			<path style="fill:#263238;" d="M327.202,141.752c-0.826,0.156-1.573-0.157-1.67-0.698c-0.097-0.541,0.494-1.106,1.319-1.262
				c0.825-0.155,1.573,0.157,1.67,0.698C328.618,141.031,328.027,141.596,327.202,141.752z"/>
			<path style="fill:#263238;" d="M321.646,129.308c-0.042,0.646-0.419,1.144-0.841,1.112c-0.422-0.031-0.731-0.58-0.688-1.226
				c0.042-0.646,0.419-1.143,0.841-1.112C321.38,128.113,321.688,128.662,321.646,129.308z"/>
			<g>
				<path style="fill:#263238;" d="M323.642,135.748c-4.685,0.32-5.578,2.605-4.762,3.604c0.816,0.999,2.823,0.017,2.823,0.017
					s3.783,0.808,4.898-0.395c1.788,0.761,4.514-0.662,3.749-1.742C329.584,136.152,327.645,135.474,323.642,135.748z"/>
				<path style="opacity:0.2;fill:#FFFFFF;" d="M323.642,135.748c-4.685,0.32-5.578,2.605-4.762,3.604
					c0.816,0.999,2.823,0.017,2.823,0.017s3.783,0.808,4.898-0.395c1.788,0.761,4.514-0.662,3.749-1.742
					C329.584,136.152,327.645,135.474,323.642,135.748z"/>
			</g>
			<path style="fill:#FF5652;" d="M323.78,129.968c0,0-1.713,3.615-3.447,5.292c1.073,1.151,3.049,0.808,3.049,0.808L323.78,129.968
				z"/>
			<path style="fill:#263238;" d="M331.812,126.638c0.063-0.004,0.126-0.023,0.183-0.06c0.175-0.114,0.224-0.349,0.108-0.526
				c-1.157-1.767-2.913-1.795-2.987-1.795c-0.21-0.001-0.379,0.169-0.378,0.379c0.001,0.209,0.171,0.38,0.38,0.382l0,0
				c0.058,0.001,1.426,0.037,2.35,1.447C331.546,126.584,331.68,126.646,331.812,126.638z"/>
			<path style="fill:#263238;" d="M318.779,125.89c0.123,0.001,0.244-0.057,0.318-0.166c0.933-1.387,2.302-1.389,2.36-1.388
				c0.209,0.002,0.38-0.165,0.382-0.374c0.001-0.209-0.166-0.382-0.375-0.386c-0.076,0-1.831-0.017-2.999,1.72
				c-0.117,0.174-0.07,0.41,0.105,0.528C318.634,125.868,318.707,125.89,318.779,125.89z"/>
			<g>
				<path style="fill:#263238;" d="M331.689,310.8c2.237-36.078,1.743-73.102-2.149-81.16l-25.75-0.394
					c0,0,3.191,60.325,4.204,84.357c1.05,24.988,3.284,89.963,3.284,89.963l14.898,0.184
					C326.175,403.75,334.931,338.71,331.689,310.8z"/>
				<path style="opacity:0.2;" d="M332.799,255.65c-8.79,9.6-4.68,36.18-0.85,50.82C332.969,288.16,333.279,269.93,332.799,255.65z"
					/>
				<path style="fill:#263238;" d="M325.972,405.663l-13.106-0.169c-0.801-0.013-1.661,0.17-2.319,0.689
					c-3.793,2.994-6.688,3.09-10.999,3.862c-5.168,0.926-2.612,6.123,1.198,6.183c6.301,0.099,5.778-0.133,11.15-0.048
					c1.213,0.019,3.726,0.048,3.726,0.048l1.683-1.712l1.484,1.712c0,0,4.125,0,6.876,0c0.761,0,1.365-0.628,1.284-1.385
					L325.972,405.663z"/>
				<path class="text-primary" style="fill:currentColor;" d="M327.032,406.958c-0.232-0.041-17.188-0.541-17.188-0.541l-0.694-6.083l18.806,0.417
					L327.032,406.958z"/>
			</g>
			<g>
				<path style="fill:#263238;" d="M361.927,310.8c-2.759-36.078-3.935-72.8-8.943-80.858l-31.057-0.4
					c0,0,12.352,60.029,16.692,84.061c4.51,24.988,16.652,89.963,16.652,89.963l14.923,0.184
					C370.195,403.75,369.034,338.71,361.927,310.8z"/>
				<path style="fill:#263238;" d="M370.93,405.663l-13.106-0.169c-0.801-0.013-1.661,0.17-2.319,0.689
					c-3.793,2.994-2.059,3.09-6.37,3.862c-5.168,0.926-2.612,6.123,1.198,6.183c6.301,0.099,5.777-0.133,11.15-0.048
					c1.213,0.019,5.833,0.048,5.833,0.048l0.778-1.712l1.028,1.712h1.536c0.746,0,1.328-0.647,1.249-1.389L370.93,405.663z"/>
				<path class="text-primary" style="fill:currentColor;" d="M371.915,406.958c-0.238-0.041-17.269-0.541-17.269-0.541l-1.605-6.083l18.868,0.417
					L371.915,406.958z"/>
			</g>
			<path class="text-primary" style="fill:currentColor;" d="M323.315,154.294c0,0-3.692,6.567,0.547,11.895c-3.359,0.022-5.559,3.643-5.559,3.643
				s-4.472-8.979,2.132-14.958C320.902,154.153,323.315,154.294,323.315,154.294z"/>
			<g>
				<path style="fill:#263238;" d="M354.333,232.684c-17.573,0.727-37.725,0.95-50.327-0.001c-0.139-0.011-0.267-0.076-0.347-0.19
					c-0.788-1.118-1.176-3.041-1.086-4.645c0.014-0.246,0.231-0.431,0.478-0.424c16.633,0.446,33.161,0.423,50.537-0.004
					c0.195-0.005,0.378,0.111,0.45,0.292c0.555,1.405,0.851,2.931,0.745,4.536C354.767,232.486,354.57,232.675,354.333,232.684z"/>
				<path style="opacity:0.2;" d="M354.333,232.684c-17.573,0.727-37.725,0.95-50.327-0.001c-0.139-0.011-0.267-0.076-0.347-0.19
					c-0.788-1.118-1.176-3.041-1.086-4.645c0.014-0.246,0.231-0.431,0.478-0.424c16.633,0.446,33.161,0.423,50.537-0.004
					c0.195-0.005,0.378,0.111,0.45,0.292c0.555,1.405,0.851,2.931,0.745,4.536C354.767,232.486,354.57,232.675,354.333,232.684z"/>
			</g>
			<path style="fill:#FFFFFF;" d="M319.998,227.143c0,0-0.378,4.025,0.532,6.589c3.813,0.589,11.635,0.148,11.635,0.148
				s0.208-3.722-0.248-6.644C325.92,226.958,319.998,227.143,319.998,227.143z"/>
			<g>
				<path style="fill:#FF8B7B;" d="M355.133,166.724l0.567,0.505l0.452,0.42l0.845,0.816c0.552,0.541,1.055,1.087,1.563,1.636
					c1.003,1.094,1.95,2.214,2.848,3.354c1.796,2.283,3.466,4.631,4.961,7.069c1.5,2.434,2.908,4.913,4.138,7.47
					c1.275,2.534,2.396,5.133,3.396,7.769c0.264,0.705,0.474,1.135,0.858,2.346c0.324,1.036,0.522,2.048,0.634,3.009
					c0.221,1.936,0.129,3.701-0.07,5.354c-0.401,3.306-1.353,6.174-2.391,8.912c-1.091,2.715-2.342,5.267-3.747,7.702
					c-1.417,2.452-2.887,4.732-4.672,7.034c-1.184,1.527-3.381,1.804-4.908,0.621c-1.217-0.944-1.641-2.533-1.162-3.912l0.06-0.17
					c1.652-4.731,3.302-9.697,4.238-14.389c0.491-2.332,0.775-4.657,0.756-6.646c-0.015-0.989-0.132-1.89-0.321-2.575
					c-0.221-0.792-0.265-0.738-0.975-2.171c-2.244-4.443-4.704-8.741-7.481-12.763c-1.373-2.023-2.837-3.96-4.338-5.827
					c-0.744-0.941-1.529-1.831-2.294-2.71l-1.158-1.264l-0.561-0.589l-0.264-0.268c-0.066-0.066-0.195-0.191-0.165-0.169
					c-2.917-2.537-3.224-6.958-0.687-9.876C347.795,164.494,352.216,164.186,355.133,166.724z"/>
				<path class="text-primary" style="fill:currentColor;" d="M346.506,158.059c7.79,1.35,15.558,10.634,20.182,19.248c-5.352,9.018-13.712,12.22-13.712,12.22
					s-9.132-8.896-12.789-15.421C336.53,167.581,339.177,156.789,346.506,158.059z"/>
				<path style="fill:#263238;" d="M366.416,175.342c0,0,1.449,1.746,1.629,2.813c-1.992,2.973-6.734,10.933-15.405,12.484
					c-1.454-0.909-1.77-2.157-1.77-2.157S360.875,183.721,366.416,175.342z"/>
				<path style="fill:#263238;" d="M340.868,157.364c-1.611-0.69-0.969-1.607-0.969-1.607s7.41,0.262,12.48,1.874
					c0.753,0.889-0.129,1.956-0.129,1.956S345.651,159.411,340.868,157.364z"/>
				<path style="opacity:0.2;" d="M338.929,155.561c0,0-1.681,5.856-9.36,10.639c3.359,0.022,5.614,6.133,5.614,6.133
					s6.957-8.222,5.379-16.333C340.095,155.278,338.929,155.561,338.929,155.561z"/>
				<path class="text-primary" style="fill:currentColor;" d="M338.706,154.5c0,0-1.996,6.344-9.137,11.7c3.359,0.022,5.693,3.633,5.693,3.633
					s6.878-5.722,5.3-13.833C340.095,155.278,338.706,154.5,338.706,154.5z"/>
				<path style="fill:#FFFFFF;" d="M345.91,178.571c-0.464,8.64-5.925,10.696-6.697,10.696c-0.772,0-6.012-2.056-5.547-10.696
					l6.254-2.46L345.91,178.571z"/>
				<path style="fill:#FF8B7B;" d="M359.372,226.102l-6.157,5.297l8.167,5.309c0,0,4.993-5.473,2.514-8.596L359.372,226.102z"/>
				<path style="fill:#FF8B7B;" d="M352.517,239.806l3.176,1.378c1.264,0.549,2.74,0.101,3.486-1.058l2.202-3.419l-8.167-5.309
					l-2.135,4.651C350.421,237.484,351.069,239.178,352.517,239.806z"/>
			</g>
			<g>
				<path style="fill:#263238;" d="M317.695,119.135c-5.488,0.741-3.643,2.486,8.597,4.049c12.24,1.563,15.303,0.33,15.303,0.33
					S327.541,119.098,317.695,119.135z"/>
				<path class="text-primary" style="fill:currentColor;" d="M345.387,125.89c0,0-10.749-4.374-27.692-6.755c-0.208-2.49,1.844-5.13,1.844-5.13
					s-3.776-1.895-2.604-5.509s6.571-3.133,12.88-6.035c2.313,2.544,8.661,6.671,12.993,7.201c1.074,2.207,3.873,7.734,8.41,9.87
					c-0.065,2.409-4.577,3.034-4.577,3.034S346.854,124.188,345.387,125.89z"/>
				<path style="opacity:0.3;" d="M346.642,122.565c-3.928-3.57-17.894-10.084-27.103-8.56c0,0-2.052,2.64-1.844,5.13
					c16.943,2.381,27.692,6.756,27.692,6.756C346.854,124.188,346.642,122.565,346.642,122.565z"/>
				<path style="fill:#FFFFFF;" d="M331.439,109.84c-1.209,6.164-5.413,7.267-5.978,7.215c-0.565-0.053-4.194-1.883-2.986-8.047
					l4.826-1.339L331.439,109.84z"/>
			</g>
		</g>
		<g>
			<path style="fill:#263238;" d="M251.749,210.779c-1.245,0-2.323-0.929-2.479-2.195l-7.138-58.197
				c-0.168-1.371,0.807-2.618,2.177-2.786c1.368-0.177,2.618,0.807,2.786,2.177l7.138,58.197c0.168,1.371-0.807,2.618-2.177,2.786
				C251.954,210.773,251.851,210.779,251.749,210.779z"/>
			<path style="fill:#FF8B7B;" d="M250.156,184.604c-0.604-2.24,0.541-2.576-0.566-5.753c-0.589-1.69,1.346-2.994,1.591-1.638
				c0.894,4.947,7.473,7.717,5.187,10.844C254.045,191.232,250.761,186.844,250.156,184.604z"/>
			
				<ellipse transform="matrix(0.0626 -0.998 0.998 0.0626 126.183 328.814)" class="text-primary" style="fill:currentColor;" cx="238.132" cy="97.235" rx="56.714" ry="56.714"/>
			
				<ellipse transform="matrix(0.334 -0.9426 0.9426 0.334 66.9429 289.2134)" style="fill:#FFFFFF;" cx="238.132" cy="97.235" rx="45.801" ry="45.801"/>
			<path style="fill:#263238;" d="M267.872,89.486c-3.763-5.072-10.971,0.003-13.17,5.347c-0.583,1.416-1.15,3.24-1.157,4.149
				l-2.382-16.317l-1.896-15.454c-0.378-3.078-3.373-5.126-6.378-4.359c-2.508,0.639-4.151,3.043-3.836,5.612l-0.472-3.851
				c-0.378-3.079-3.373-5.126-6.378-4.359c-2.508,0.639-4.151,3.043-3.836,5.612l0.658,5.368c-0.378-3.078-3.373-5.126-6.378-4.359
				l0,0c-2.508,0.64-4.151,3.043-3.836,5.612l0.821,6.69c-0.378-3.078-3.373-5.126-6.378-4.359l0,0
				c-2.508,0.64-4.151,3.043-3.836,5.612l1.64,13.373l1.176,9.588l1.545,12.594c1.098,8.951,4.61,14.072,9.824,16.361
				c8.012,3.518,22.645,3.559,29.227-1.782c5.756-4.672,9.462-15.926,10.354-22.891C264.072,100.743,269.397,91.541,267.872,89.486z
				"/>
			
				<rect x="232.208" y="42.469" transform="matrix(0.7879 0.6158 -0.6158 0.7879 110.3727 -126.0115)" class="text-primary" style="fill:currentColor;" width="11.848" height="109.533"/>
		</g>
	</g>
</g>
<g id="Error_Unauthorized">
	<g>
		<g>
			<path style="fill:#263238;" d="M80.441,180.083v-18.207h13.165v2.149H82.851v5.576h10.072v2.137H82.851v6.197h11.178v2.149
				H80.441z"/>
			<path style="fill:#263238;" d="M97.047,180.083v-13.19h2.012v1.999c0.513-0.936,0.987-1.553,1.422-1.85
				c0.435-0.298,0.913-0.448,1.434-0.448c0.754,0,1.52,0.241,2.298,0.721l-0.77,2.074c-0.547-0.324-1.093-0.485-1.639-0.485
				c-0.489,0-0.928,0.147-1.317,0.44c-0.39,0.294-0.667,0.702-0.832,1.224c-0.249,0.795-0.373,1.664-0.373,2.608v6.906H97.047z"/>
			<path style="fill:#263238;" d="M105.517,180.083v-13.19h2.012v1.999c0.513-0.936,0.987-1.553,1.422-1.85
				c0.435-0.298,0.913-0.448,1.434-0.448c0.754,0,1.52,0.241,2.298,0.721l-0.77,2.074c-0.547-0.324-1.093-0.485-1.639-0.485
				c-0.489,0-0.928,0.147-1.317,0.44c-0.39,0.294-0.667,0.702-0.832,1.224c-0.249,0.795-0.373,1.664-0.373,2.608v6.906H105.517z"/>
			<path style="fill:#263238;" d="M113.179,173.488c0-2.442,0.679-4.251,2.037-5.427c1.134-0.977,2.517-1.465,4.148-1.465
				c1.813,0,3.296,0.595,4.446,1.783c1.151,1.188,1.727,2.83,1.727,4.924c0,1.698-0.255,3.033-0.764,4.005
				c-0.509,0.973-1.25,1.729-2.223,2.268c-0.973,0.538-2.035,0.807-3.186,0.807c-1.846,0-3.339-0.592-4.477-1.777
				C113.749,177.421,113.179,175.715,113.179,173.488z M115.477,173.488c0,1.689,0.368,2.954,1.105,3.795
				c0.737,0.84,1.664,1.26,2.782,1.26c1.11,0,2.033-0.423,2.769-1.267s1.105-2.132,1.105-3.862c0-1.631-0.37-2.868-1.111-3.708
				c-0.741-0.84-1.662-1.26-2.764-1.26c-1.118,0-2.045,0.418-2.782,1.254C115.845,170.537,115.477,171.799,115.477,173.488z"/>
			<path style="fill:#263238;" d="M128.133,180.083v-13.19h2.012v1.999c0.513-0.936,0.987-1.553,1.422-1.85
				c0.435-0.298,0.913-0.448,1.434-0.448c0.754,0,1.52,0.241,2.298,0.721l-0.77,2.074c-0.547-0.324-1.093-0.485-1.639-0.485
				c-0.489,0-0.928,0.147-1.317,0.44c-0.389,0.294-0.667,0.702-0.832,1.224c-0.249,0.795-0.373,1.664-0.373,2.608v6.906H128.133z"/>
			<path style="fill:#263238;" d="M88.75,202.688v-1.937c-1.027,1.491-2.422,2.236-4.185,2.236c-0.778,0-1.505-0.149-2.18-0.448
				c-0.675-0.298-1.176-0.673-1.503-1.124c-0.327-0.451-0.557-1.004-0.69-1.658c-0.091-0.439-0.137-1.134-0.137-2.087v-8.172h2.236
				v7.315c0,1.167,0.045,1.954,0.137,2.359c0.14,0.589,0.438,1.05,0.894,1.385c0.455,0.335,1.018,0.503,1.689,0.503
				s1.3-0.171,1.888-0.515c0.588-0.344,1.004-0.812,1.248-1.403c0.244-0.592,0.366-1.451,0.366-2.578v-7.066h2.236v13.19H88.75z"/>
			<path style="fill:#263238;" d="M94.252,202.688v-13.19h2.012v1.875c0.969-1.449,2.368-2.174,4.198-2.174
				c0.795,0,1.525,0.143,2.192,0.429c0.666,0.286,1.165,0.66,1.496,1.124c0.331,0.464,0.563,1.014,0.696,1.652
				c0.083,0.413,0.124,1.139,0.124,2.173v8.11h-2.235v-8.023c0-0.911-0.087-1.592-0.261-2.044c-0.174-0.451-0.482-0.812-0.925-1.081
				c-0.443-0.268-0.962-0.404-1.558-0.404c-0.952,0-1.775,0.303-2.466,0.907c-0.691,0.604-1.037,1.751-1.037,3.441v7.203H94.252z"/>
			<path style="fill:#263238;" d="M117.005,201.061c-0.828,0.704-1.625,1.201-2.391,1.491s-1.588,0.435-2.465,0.435
				c-1.449,0-2.563-0.354-3.341-1.063c-0.778-0.707-1.167-1.611-1.167-2.713c0-0.645,0.147-1.236,0.44-1.77
				c0.294-0.534,0.679-0.962,1.155-1.285c0.476-0.324,1.012-0.568,1.608-0.734c0.439-0.116,1.101-0.227,1.987-0.335
				c1.805-0.214,3.134-0.471,3.987-0.77c0.008-0.306,0.012-0.5,0.012-0.583c0-0.911-0.211-1.553-0.633-1.926
				c-0.571-0.505-1.42-0.757-2.546-0.757c-1.052,0-1.827,0.184-2.328,0.552c-0.502,0.369-0.871,1.021-1.112,1.956l-2.186-0.297
				c0.199-0.936,0.526-1.691,0.981-2.266c0.455-0.576,1.113-1.019,1.975-1.33c0.861-0.31,1.859-0.466,2.993-0.466
				c1.126,0,2.041,0.132,2.745,0.397c0.704,0.266,1.222,0.599,1.553,1.001c0.331,0.402,0.563,0.908,0.695,1.521
				c0.075,0.38,0.112,1.068,0.112,2.061v2.981c0,2.078,0.047,3.392,0.143,3.943c0.095,0.551,0.283,1.079,0.565,1.583h-2.335
				C117.22,202.225,117.072,201.682,117.005,201.061z M116.819,196.069c-0.812,0.331-2.029,0.613-3.651,0.844
				c-0.919,0.132-1.569,0.282-1.95,0.448c-0.381,0.165-0.675,0.408-0.882,0.726c-0.207,0.319-0.311,0.673-0.311,1.062
				c0,0.596,0.226,1.093,0.677,1.491c0.451,0.397,1.112,0.596,1.981,0.596c0.861,0,1.627-0.188,2.298-0.566
				c0.671-0.376,1.163-0.891,1.478-1.546c0.24-0.505,0.36-1.25,0.36-2.235V196.069z"/>
			<path style="fill:#263238;" d="M131.189,202.688v-1.937c-1.027,1.491-2.422,2.236-4.185,2.236c-0.778,0-1.505-0.149-2.18-0.448
				c-0.675-0.298-1.176-0.673-1.503-1.124c-0.327-0.451-0.557-1.004-0.69-1.658c-0.091-0.439-0.137-1.134-0.137-2.087v-8.172h2.236
				v7.315c0,1.167,0.045,1.954,0.137,2.359c0.14,0.589,0.438,1.05,0.894,1.385c0.455,0.335,1.018,0.503,1.689,0.503
				s1.3-0.171,1.888-0.515c0.588-0.344,1.004-0.812,1.248-1.403c0.244-0.592,0.366-1.451,0.366-2.578v-7.066h2.235v13.19H131.189z"
				/>
			<path style="fill:#263238;" d="M141.572,200.689l0.323,1.974c-0.63,0.133-1.193,0.199-1.689,0.199
				c-0.812,0-1.441-0.128-1.888-0.385c-0.447-0.257-0.762-0.594-0.943-1.012c-0.182-0.417-0.273-1.297-0.273-2.639v-7.589h-1.639
				v-1.739h1.639v-3.266l2.223-1.341v4.607h2.248v1.739h-2.248v7.713c0,0.637,0.039,1.047,0.118,1.229
				c0.078,0.183,0.207,0.328,0.385,0.435c0.178,0.107,0.433,0.162,0.764,0.162C140.839,200.776,141.166,200.747,141.572,200.689z"/>
			<path style="fill:#263238;" d="M143.758,202.688v-18.207h2.236v6.532c1.043-1.209,2.36-1.813,3.949-1.813
				c0.977,0,1.826,0.192,2.546,0.578c0.721,0.385,1.236,0.917,1.546,1.596c0.311,0.679,0.466,1.664,0.466,2.956v8.358h-2.235v-8.358
				c0-1.118-0.242-1.931-0.726-2.441c-0.485-0.509-1.17-0.763-2.056-0.763c-0.662,0-1.286,0.171-1.869,0.515
				c-0.584,0.344-1,0.809-1.248,1.397c-0.249,0.588-0.373,1.399-0.373,2.435v7.215H143.758z"/>
			<path style="fill:#263238;" d="M157.072,196.093c0-2.442,0.679-4.251,2.037-5.427c1.134-0.977,2.517-1.465,4.148-1.465
				c1.813,0,3.296,0.595,4.446,1.783c1.151,1.188,1.727,2.83,1.727,4.924c0,1.698-0.255,3.033-0.764,4.005
				c-0.509,0.973-1.25,1.729-2.223,2.268c-0.973,0.538-2.035,0.807-3.186,0.807c-1.846,0-3.339-0.592-4.477-1.777
				C157.641,200.027,157.072,198.321,157.072,196.093z M159.37,196.093c0,1.689,0.368,2.954,1.105,3.795
				c0.737,0.84,1.664,1.26,2.782,1.26c1.109,0,2.033-0.423,2.769-1.267c0.737-0.844,1.105-2.132,1.105-3.862
				c0-1.632-0.371-2.868-1.111-3.708c-0.741-0.84-1.662-1.26-2.764-1.26c-1.118,0-2.045,0.418-2.782,1.254
				C159.738,193.142,159.37,194.405,159.37,196.093z"/>
			<path style="fill:#263238;" d="M172.026,202.688v-13.19h2.012v1.999c0.513-0.936,0.987-1.553,1.422-1.85
				c0.435-0.299,0.913-0.448,1.434-0.448c0.754,0,1.52,0.241,2.298,0.721l-0.77,2.074c-0.547-0.324-1.093-0.485-1.639-0.485
				c-0.489,0-0.928,0.147-1.317,0.44c-0.389,0.294-0.667,0.702-0.832,1.224c-0.249,0.795-0.373,1.664-0.373,2.608v6.905H172.026z"/>
			<path style="fill:#263238;" d="M180.533,187.052v-2.57h2.235v2.57H180.533z M180.533,202.688v-13.19h2.235v13.19H180.533z"/>
			<path style="fill:#263238;" d="M184.991,202.688v-1.813l8.396-9.638c-0.952,0.049-1.792,0.075-2.521,0.075h-5.378v-1.813h10.78
				v1.478l-7.141,8.371l-1.379,1.527c1.002-0.075,1.942-0.111,2.819-0.111h6.098v1.925H184.991z"/>
			<path style="fill:#263238;" d="M207.918,198.441l2.31,0.286c-0.364,1.35-1.039,2.397-2.024,3.142
				c-0.985,0.745-2.244,1.118-3.775,1.118c-1.93,0-3.459-0.595-4.589-1.783c-1.131-1.188-1.696-2.854-1.696-4.999
				c0-2.219,0.571-3.941,1.714-5.167c1.143-1.226,2.625-1.839,4.446-1.839c1.764,0,3.205,0.601,4.322,1.801
				c1.118,1.202,1.677,2.89,1.677,5.068c0,0.133-0.004,0.331-0.012,0.596h-9.837c0.082,1.449,0.492,2.559,1.23,3.328
				c0.736,0.771,1.656,1.155,2.757,1.155c0.82,0,1.52-0.215,2.099-0.645C207.119,200.072,207.578,199.385,207.918,198.441z
				 M200.578,194.826h7.365c-0.099-1.109-0.381-1.942-0.845-2.496c-0.712-0.861-1.635-1.292-2.769-1.292
				c-1.027,0-1.89,0.344-2.59,1.031C201.039,192.757,200.652,193.676,200.578,194.826z"/>
			<path style="fill:#263238;" d="M221.592,202.688v-1.664c-0.836,1.308-2.066,1.963-3.688,1.963c-1.052,0-2.018-0.29-2.9-0.869
				c-0.882-0.58-1.565-1.39-2.049-2.428c-0.484-1.04-0.726-2.234-0.726-3.584c0-1.316,0.219-2.51,0.658-3.583
				c0.439-1.072,1.098-1.894,1.975-2.465c0.878-0.572,1.859-0.858,2.944-0.858c0.795,0,1.503,0.168,2.123,0.503
				c0.621,0.335,1.126,0.772,1.515,1.31v-6.532h2.223v18.207H221.592z M214.525,196.106c0,1.689,0.356,2.952,1.068,3.789
				c0.712,0.836,1.552,1.254,2.521,1.254c0.977,0,1.807-0.399,2.49-1.198c0.683-0.799,1.024-2.018,1.024-3.658
				c0-1.805-0.347-3.13-1.043-3.974c-0.695-0.845-1.552-1.267-2.571-1.267c-0.993,0-1.824,0.406-2.49,1.217
				C214.859,193.08,214.525,194.359,214.525,196.106z"/>
		</g>
	</g>
</g>
</svg>
        </div>
    </div>
</div>
