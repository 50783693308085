import {gql} from 'apollo-angular';

import {PersonCompanyRelationTypeCreateInput} from "../../../../@interfaces/person-company-relation-type.interface";

export const UPDATE_PERSON_COMPANY_ADDITIONAL_INFORMATION = gql`
    mutation updatePersonCompanyAdditionalInformation($id: ID!, $input: PersonCompanyAdditionalInformationUpdateInput!) {
        updatePersonCompanyAdditionalInformation(id: $id, input: $input) {
            status
            message
            timeStamp
            data {
                id
                relationType
                personCompanyType
                status
                personCompanyId
            }
            errors {
                field
                type
            }
        }
    }
`;
