<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation
    class="dark bg-gray-900 print:hidden"
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'"
    [navigation]="navigation.default"
    [opened]="!isScreenSmall"
>
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <div class="flex w-full items-center p-4 pl-6">
            <!-- Logo -->
            <div class="flex items-center justify-center">
                <img class="w-8" src="images/logo/logo.svg" />
            </div>
            <!-- Components -->
            <div class="ml-auto flex items-center">
                @if (showAdditionalFeatures) {
                    <notifications></notifications>
                }
                <user [showAvatar]="false"></user>
            </div>
        </div>
        <!-- User -->
        <div class="flex w-full flex-col items-center p-4">
            <div class="relative h-24 w-24" *ngIf="user$ | async as user">
                @if (user?.avatar) {
                    <img class="h-full w-full rounded-full" [src]="user.avatar" alt="User avatar" />
                } @else {
                    <div class="opacity-90">
                        <ngx-avatars
                            [name]="
                                user?.personCompany?.person?.firstName +
                                ' ' +
                                user?.personCompany?.person?.lastName
                            "
                            size="96"
                            textSizeRatio="2.25"
                            initialsSize="2"
                        ></ngx-avatars>
                    </div>
                }
            </div>
            <div class="mt-6 flex w-full flex-col items-center justify-center">
                <div
                    *ngIf="user$ | async as user"
                    class="w-full overflow-hidden text-ellipsis whitespace-nowrap text-center font-medium leading-normal"
                >
                    {{ user?.personCompany?.person?.firstName }}
                    {{ user?.personCompany?.person?.lastName }}
                </div>
                <!-- @if (user?.email) {
                    <div
                        class="text-secondary mt-0.5 w-full overflow-hidden text-ellipsis whitespace-nowrap text-center text-md font-medium leading-normal"
                    >
                        {{ user$.email }}
                    </div>
                } -->
            </div>
        </div>
    </ng-container>
    <!-- Navigation footer hook -->
    <ng-container fuseVerticalNavigationContentFooter>
        <div class="my-4 flex h-16 flex-0 items-center justify-center py-2 opacity-12">
            <img class="max-w-36" src="images/logo/logo-text-on-dark.svg" />
        </div>
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex w-full min-w-0 flex-auto flex-col">
    <!-- Header -->
    <div
        class="bg-card sticky top-0 z-49 flex h-16 w-full flex-0 items-center px-4 shadow dark:border-b dark:shadow-none md:px-6 print:hidden"
    >
        <!-- Navigation toggle button -->
        <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
        </button>
        <!-- Components -->
        <div class="ml-auto flex items-center space-x-0.5 pl-2 sm:space-x-2">
            <languages></languages>
            <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
            <app-current-page-settings-button></app-current-page-settings-button>

            @if (showAdditionalFeatures) {
                <search [appearance]="'bar'"></search>
                <shortcuts></shortcuts>
                <messages></messages>
            }
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-auto flex-col">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        @if (true) {
            <router-outlet></router-outlet>
        }
    </div>

    <!-- Footer -->
    <div
        class="bg-card relative z-49 flex h-14 w-full flex-0 items-center justify-start border-t px-4 dark:bg-transparent md:px-6 print:hidden"
    >
        <span class="text-secondary font-medium">Artek &copy; {{ currentYear }}</span>
    </div>
</div>
