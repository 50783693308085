import {AfterViewInit, Component, inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {MatDrawerMode, MatSidenav, MatSidenavContainer, MatSidenavContent} from '@angular/material/sidenav';
import {delay, map, takeUntil, tap} from 'rxjs/operators';
import {LocalStorageService} from '../../../../services/local-storage.service';
import {FooterComponent} from '../footer/footer.component';
import {HeaderComponent} from '../header/header.component';
import {MainMenuComponent} from '../menu/main-menu/main-menu.component';
import {MatIcon} from '@angular/material/icon';
import {MatButton} from '@angular/material/button';
import {RouterLink, RouterOutlet} from '@angular/router';
import {NgClass, NgIf} from '@angular/common';
import {
    ScreenSizeEnum,
    ScreenSizeSignalService,

} from "../../../../services/screen-size-signal.service";
import { MainTemplateService } from './services/main-template.service';
import {Subject} from "rxjs";

@Component({
    selector: 'app-main-template',
    templateUrl: './main-template.component.html',
    styleUrls: ['./main-template.component.scss'],
    standalone: true,
    imports: [MatSidenavContainer, MatSidenav, NgClass, RouterLink, NgIf, MatButton, MatIcon, MainMenuComponent, MatSidenavContent, HeaderComponent, RouterOutlet, FooterComponent]
})


export class MainTemplateComponent implements  OnDestroy{
    // "We add a ViewChild decorator to get the mat-sidenav component reference in our code"
    @ViewChild(MatSidenav)
        // "we name it "sidenav" and... ???"
    sidenav!: MatSidenav;

    menuIsPinned: boolean;   // El menu está 'fijado', no se oculta
    menuIsExpanded: boolean; // El menu está expandido en toda su plenitud

    sidenavBackgroundImage: number;  // Clase css del menu
    sidenavBackgroundColor: number;  // Clase css del menu

    menuFontSize: number;

    private destroy$ = new Subject<void>();
    private observer: BreakpointObserver = inject(BreakpointObserver);
    private localStorageService: LocalStorageService = inject(LocalStorageService);

    sideNavConfig = {
        small : { mode: 'over' as MatDrawerMode, opened: false, action: (sidenav: MatSidenav) => { return sidenav.close()}, size: 'small' },
        medium : { mode: 'side' as MatDrawerMode, opened: true, action: (sidenav: MatSidenav) => {return   sidenav.open()}, size: 'medium' },
        large : { mode: 'side' as MatDrawerMode, opened: true, action: (sidenav: MatSidenav) => { return sidenav.open()}, size: 'large' },
    }
    sideNavCurrentConfig = this.sideNavConfig.large;
    private defaultOptions = {
        menuIsPinned: false,
        menuFontSize: 100,
    }
    // "We include a "BreakpointObserver" in our constructor
    constructor() {
        this.menuIsPinned = this.initialParamValue('menuIsPinned');
        this.sidenavBackgroundColor = this.initialParamValue('sidenavBackgroundColor');
        this.sidenavBackgroundImage = this.initialParamValue('sidenavBackgroundImage');
        this.menuFontSize = this.initialParamValue('menuFontSize');
        this.menuIsExpanded = this.menuIsPinned;

    }

    ngOnDestroy() {
        this.destroy$.next(null);
        this.destroy$.complete();
    }

    mainMenuToggle(): void {
        this.sidenav.toggle();
    }


    togglePinMenu(): void {
        this.menuIsPinned = !this.menuIsPinned;
        if (this.menuIsPinned) {
            this.sidenav.mode = 'side';
        }

        this.localStorageService.setItem('usrPrefs_menuIsPinned', this.menuIsPinned);      // Guardamos la preferencia del usuario en local storage
    }

    expandMenu(): void {
        this.menuIsExpanded = true;
    }

    contractMenu(): void {
        if (!this.menuIsPinned) {
            this.menuIsExpanded = false;
        }

    }

    // "Any code to access the sidenav should be in the ngAfterViewInit because otherwise, our sidenav instance might not have been initialized"
    ngAfterViewInit() {
        // console.log('ngAfterViewInit...')
        /*Creamos un observer para que esté al loro del ancho de la pantalla y actualice en consecuencia el "modo" del "mat-sidenav" */
        this.observer.observe([
            Breakpoints.XSmall,
            Breakpoints.Small,
            Breakpoints.Medium,
            Breakpoints.Large,
            Breakpoints.XLarge,

        ]).pipe(
            takeUntil(this.destroy$),
            tap(result => console.log(result)),
            map((result) => {
                if (result.breakpoints[Breakpoints.XSmall]) {
                    return 'small';
                }
                if (result.breakpoints[Breakpoints.Small]) {
                    return 'small';
                }
                if (result.breakpoints[Breakpoints.Medium]) {
                    return 'medium';
                }
                if (result.breakpoints[Breakpoints.Large]) {
                    return 'large';
                }
                if (result.breakpoints[Breakpoints.XLarge]) {
                    return 'large';
                }
                return 'large';
            })

        ).subscribe(size => {
            this.sideNavCurrentConfig = this.sideNavConfig[size];
            if ( this.sidenav.mode !== this.sideNavCurrentConfig.mode )
            {
                this.sidenav.mode = this.sideNavCurrentConfig.mode;
                this.sideNavCurrentConfig.action(this.sidenav).then((r) => console.log('RESULTACTION', r))
            }
        })

        // this.log_status()
    }

    log_status(): void {
        console.log('LOG_STATUS');
        console.log('this.sidenav.mode=' + this.sidenav.mode);
        console.log('this.sidenav.opened=' + this.sidenav.opened);
        console.log('this.menuIsPinned=' + this.menuIsPinned);
        console.log('this.menuIsExpanded=' + this.menuIsExpanded);
    }

    // Valor inicial a aplicar a cada parametro de preferencias de usuario. El que haya en local storage o en su defecto el valor por defecto.
    initialParamValue(userPrefsParam: string): any {
        return this.localStorageService.getItem('usrPrefs_' + userPrefsParam) ?? this.defaultOptions[userPrefsParam];

    }

    // Cambia el color de fondo del menu (rotando secuencialmente)
    rollsidenavBackgroundColor(): void {
        const numColors = 9;  // Numero total de clases css definidas para
        this.sidenavBackgroundColor += 1;
        if (this.sidenavBackgroundColor > numColors) {
            this.sidenavBackgroundColor = 1;
        }

        // chapu!
        if (this.sidenavBackgroundColor == 9) {  // white
            // PENDIENTE: dejar todos los links del menu mas oscuros. En vez de poner los colores a pelo en los css, deberiamos modificar los styles predefinidos
        }

        this.localStorageService.setItem('usrPrefs_sidenavBackgroundColor', JSON.stringify(this.sidenavBackgroundColor));      // Guardamos la preferencia del usuario en local storage
        console.log('this.sidenavBackgroundColor ' + this.sidenavBackgroundColor);
    }


    // Cambia la imagen de fondo del menu (rotando secuencialmente)
    rollsidenavBackgroundImage(): void {
        const numImages = 5;  // Numero total de clases css definidas para
        this.sidenavBackgroundImage += 1;
        if (this.sidenavBackgroundImage > numImages) {
            this.sidenavBackgroundImage = 1;
        }

        this.localStorageService.setItem('usrPrefs_sidenavBackgroundImage', JSON.stringify(this.sidenavBackgroundImage));      // Guardamos la preferencia del usuario en local storage
        // console.log('this.sidenavBackgroundImage ' + this.sidenavBackgroundImage)
    }

    zoomInMenuFont(): void {
        if (this.menuFontSize < 110) {
            this.menuFontSize += 5;  // +5%
        }

        this.localStorageService.setItem('usrPrefs_menuFontSize', JSON.stringify(this.menuFontSize));      // Guardamos la preferencia del usuario en local storage
        console.log('zoomInMenuFont()' + this.menuFontSize);
    }

    zoomOutMenuFont(): void {
        if (this.menuFontSize > 90) {
            this.menuFontSize -= 5;  // -5%
        }

        this.localStorageService.setItem('usrPrefs_menuFontSize', JSON.stringify(this.menuFontSize));      // Guardamos la preferencia del usuario en local storage
        console.log('zoomOutMenuFont()' + this.menuFontSize);
    }
}
