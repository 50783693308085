import {Injectable} from '@angular/core';
import {UserAuthService} from "../admin/services/user/user-auth.service";
import {map} from "rxjs/internal/operators/map";
import {catchError, takeUntil} from "rxjs/operators";
import {Observable, of} from "rxjs";
import {IRunSearch} from "../@interfaces/run-search.interface";
import {IListResult} from "../@interfaces/list-result";
import {ApiService} from "../@graphql/services/api.service";
import {Apollo} from 'apollo-angular';
import {
    PersonCompanyAdditionalInformationCreateInput,

    PersonCompanyTypeEnum,
    RelationTypeEnum
} from "../@interfaces/person-company-additional-information.interface";
import {
    CREATE_PERSON_COMPANY_RELATION_TYPE
} from "../@graphql/operations/personCompanyRelationType/mutation/createPersonCompanyRelationType";
import {ClientSearchInput} from "../@interfaces/client/client.interface";
import {
    PersonCompanyAdditionalInformationSearchInput,
    StatusEnum
} from "../@interfaces/person-company-additional-information.interface";
import {PersonCompanyRelationTypeCreateInput} from "../@interfaces/person-company-relation-type.interface";
import {
    PERSON_COMPANY_TYPEAHEAD_QUERY
} from "../@graphql/operations/personCompanyAdditionalInformation/query/person-company-typeahead";
import {
    CREATE_PERSON_COMPANY_ADDITIONAL_INFORMATION
} from "../@graphql/operations/personCompanyAdditionalInformation/mutation/createPersonCompanyAdditionalInformation";
import {
    PERSON_COMPANY_AI_FIND_BY_PK
} from "../@graphql/operations/personCompanyAdditionalInformation/query/person-company-ai-find-by-pk";
import {GET_PERSON} from "../@graphql/operations/person/query/person";
import {
    UPDATE_PERSON_COMPANY_ADDITIONAL_INFORMATION
} from "../@graphql/operations/personCompanyAdditionalInformation/mutation/update-personCompanyAdditionalInformation";
import {isArray} from "@apollo/client/utilities";

type RelationTypeInput = RelationTypeEnum | RelationTypeEnum[] | string;
export enum PersonCompanyRelationTypeOperation {
    GET_CLIENTS = 'getClients',
    GET_CLIENT = 'getClient',
    ADD_CLIENT = 'addClient',
    UPDATE_CLIENT = 'updateClient',
    GET_REFERRERS = 'getReferrers',
    GET_REFERRER = 'getReferrer',
    ADD_REFERRER = 'addReferrer',
    UPDATE_REFERRER = 'updateReferrer',
    GET_RELATED_PERSONS = 'getRelatedPersons',
    GET_RELATED_PERSON = 'getRelatedPerson',
    ADD_RELATED_PERSON = 'addRelatedPerson',
    UPDATE_RELATED_PERSON = 'updateRelatedPerson',
}
export const personCompanyRelationTypeOperation = {
    getClients: {query: 'GET_CLIENTS', relationType: 'C'},
    getClient: 'client',
    addClient: 'addClient',
    updateClient: 'updateClient',
    getReferrers: 'referrers',
    getReferrer: 'referrer',
    addReferrer: 'addReferrer',
    updateReferrer: 'updateReferrer',
    getRelatedPersons: 'relatedPersons',
    getRelatedPerson: 'relatedPerson',
    addRelatedPerson: 'addRelatedPerson',
    updateRelatedPerson: 'updateRelatedPerson',
}

@Injectable({
    providedIn: 'root'
})
export class PersonCompanyAdditionalInformationService extends ApiService {

    constructor(private Apollo: Apollo, private userAuthService: UserAuthService) {
        super(Apollo);
    }

    findAll<A, B>(runSearchParams: IRunSearch<A>): Observable<IListResult<B>> {
        const searchParams: A = runSearchParams.searchParams;

        Object.keys(runSearchParams.searchParams).forEach(key => {
            runSearchParams.searchParams[key] == '' || runSearchParams.searchParams[key] == null ?
                delete(searchParams[key]) :
                !Array.isArray(searchParams[key]) ? searchParams[key] = searchParams[key].toString().trim() : null;
        })

        const variables = {
            searchParams: searchParams,
            limit: (runSearchParams.limit ?? 10),
            offset: (runSearchParams.offset ?? 0),
            orderBy: runSearchParams.orderBy,
        }

        console.log('SEARCHPARAMS', variables);

        return this.query(runSearchParams.query, variables, {})
            .pipe(
                map((result: any) => {
                    console.log('PersonCompanyAdditionalInformationService.service result:', result);
                    return result[Object.keys(result)[0]];
                }),

                catchError((error) => {
                    console.log('PersonCompanyAdditionalInformationService error:', error);
                    return error;
                })
            );
    }
    get(id: number|string) {
        return this.query(PERSON_COMPANY_AI_FIND_BY_PK, {id}).pipe(
            map((result: any) => {
                console.log('PERSON_COMPANY_AI_FIND_BY_PK result:', result);
                return result.findByPk;
            }),
            catchError((error) => {
                console.log('PERSON_COMPANY_AI_FIND_BY_PK error:', error);
                return of({ error: true, message: error.message });
            })
        );
    }

    mutate(input: PersonCompanyAdditionalInformationCreateInput ) {

        return (!input.id ? this._create(input) : this._update(input));
    }

    typeaheadSearch(search: string,  relationType: RelationTypeInput, personCompanyType: PersonCompanyTypeEnum): Observable<IListResult<any>>
    {
        const relationTypeValues = Object.values(RelationTypeEnum) as string[];

        const parseRelationTypes = (input: RelationTypeInput): RelationTypeEnum[] => {
            if (Array.isArray(input)) {
                return input.filter(s => relationTypeValues.includes(s)) as RelationTypeEnum[];
            } else if (typeof input === 'string') {
                return input
                    .split(',')
                    .map(s => s.trim())
                    .filter(s => relationTypeValues.includes(s))
                    .map(s => s as RelationTypeEnum);
            } else {
                return relationTypeValues.includes(input)
                    ? [input]
                    : [];
            }
        };


        const searchParams : PersonCompanyAdditionalInformationSearchInput = {
            freeSearch: search,
            relationTypeIn: parseRelationTypes(relationType),
            personCompanyType: personCompanyType,
            status: StatusEnum.ACTIVE
        };

        const runSearchParams: IRunSearch<ClientSearchInput> = {
            searchParams: searchParams,
            orderBy: [{field: 'id', direction: 'desc'}],
            query: PERSON_COMPANY_TYPEAHEAD_QUERY
        };

        return this.findAll(runSearchParams);
    }

    private _create(input: PersonCompanyAdditionalInformationCreateInput ) {
        console.log('😐 PersonCompanyAdditionalInformationService CREATE', CREATE_PERSON_COMPANY_ADDITIONAL_INFORMATION, input);
        return this.mutation(CREATE_PERSON_COMPANY_ADDITIONAL_INFORMATION, {input, skip: false}).pipe(
            map((result: any) => {
                console.log('PersonCompanyAdditionalInformationService.CREATE result:', result.createPersonCompanyAdditionalInformation);
                return result.createPersonCompanyAdditionalInformation;
            }),
            catchError((error) => {
                console.log('PersonCompanyAdditionalInformationService.CREATE error:', error);
                return error;
            })
        );
    }

    private _update(input: PersonCompanyAdditionalInformationCreateInput ) {
        console.log('😐 PersonCompanyAdditionalInformationService UPDATE', UPDATE_PERSON_COMPANY_ADDITIONAL_INFORMATION, input);
        const id  = input.id;
        return this.mutation(UPDATE_PERSON_COMPANY_ADDITIONAL_INFORMATION, {id, input, skip: false}).pipe(
            map((result: any) => {
                console.log('PersonCompanyAdditionalInformationService.UPDATE result:', result.updatePersonCompanyAdditionalInformation);
                return result.updatePersonCompanyAdditionalInformation;
            }),
            catchError((error) => {
                console.log('PersonCompanyAdditionalInformationService.UPDATE error:', error);
                return error;
            })
        );
    }
}
