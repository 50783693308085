import { Injectable, effect, signal } from '@angular/core';
import { DarkOrLight, Scheme } from '@fuse/services/config';

@Injectable({
    providedIn: 'root',
})
export class SchemeService {
    private readonly SCHEME_KEY = 'scheme';

    // Signal reactivo con el valor inicial de localStorage o "auto"
    scheme = signal<Scheme>(this.getStoredScheme());

    constructor() {
        effect(() => {
            localStorage.setItem(this.SCHEME_KEY, this.scheme());
        });
    }

    private getStoredScheme(): Scheme {
        const stored = localStorage.getItem(this.SCHEME_KEY);
        return stored === 'dark' || stored === 'light' ? stored : 'light';
    }

    // private getSystemScheme(): DarkOrLight {
    //     return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
    // }

    setScheme(scheme: Scheme) {
        this.scheme.set(scheme);
    }

    getScheme(): DarkOrLight {
        // if (this.scheme() === 'auto') {
        //     return this.getSystemScheme();
        // }
        return this.scheme() as DarkOrLight;
    }
}
